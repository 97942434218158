import React, { useMemo, useEffect, useState } from "react"
import { Row, Col, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { allCustomerWalletAction, updateCustomerWalletAction, walletActivateInactivateAction } from "../../../../store/actions/walletActions";
import "../../reacttable/reactTable.css"
import moment from "moment";
import Table from "../../reacttable/table";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";


function WalletList(props) {
    const { role } = JSON.parse(localStorage.getItem('user'));
    const [startDate, setStartDate] = useState(new Date(Date.now() - (3600 * 1000 * 360)));
    const [endDate, setEndDate] = useState(new Date(Date.now() + (3600 * 1000 * 24)));
    const [org, setOrg] = useState("All")
    useEffect(() => {
        props.getWallet(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10))
    }, [])

    const [open, setOpen] = useState(false);
    const [amt, setAmt] = useState(0)
    const [uw, setUW] = useState({})
    const closeModal = () => {
        setAmt(0)
        setOpen(false)
    };
    useEffect(() => {
        if (props.cusres.statuscode == 201) {
            window.location.reload();
        }

    }, [props.cusres])

    const activateUserWallet = (userid) => {
        props.activateDeactivateWallet(userid, "active");
        alert("Wallet has been activated")
        window.location.reload()

    }

    const deactivateUserWallet = (userid) => {
        // console.log(userid);
        props.activateDeactivateWallet(userid, "inactive");
        alert("Wallet has been Deactivated")
        window.location.reload()
    };

    const data = props.wallets && props.wallets.data

    const loadNewData = () => {
        props.getWallet(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10))

    }


    const columns = useMemo(

        () => [
            {
                Header: 'First Name',
                accessor: 'firstName'
            },
            {
                Header: 'Last Name',
                accessor: 'lastName'
            },
            {
                Header: 'Phone',
                accessor: 'phoneNumber'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'User ID',
                id: 'userid1',
                accessor: 'userid',
                Cell: (id) => {
                    return (
                        <div>
                            <span>
                                <a href={`/dashboard/walletcard/viewwallet/${id.value}`}
                                    rel="tooltip"
                                    title="View"
                                >
                                    {id.value}
                                </a></span>&nbsp;&nbsp;
                            {/* <span>
                          <a href={`/dashboard/walletcard/updatewallet/${id.value}`}
                            rel="tooltip"
                            title="Edit"
                          >
                          <FaEdit />
                          </a></span> */}


                        </div>


                    );
                },
            },
            {
                Header: 'Available Wallet Amount',
                accessor: 'amount'
            },
            {
                Header: 'Wallet Type',
                accessor: 'vendorId'
            },
            {
                Header: 'Wallet Status',
                id: "walletStatus",
                accessor: d => {
                    return d.walletStatus ? <span className="text-danger">Inactive</span> : <span className="text-success">Active</span>
                }
            },
            {
                Header: 'Wallet Created Date',
                id: 'registerat',
                accessor: d => {
                    return moment(d.registerat)
                        .local()
                        .format("DD-MM-YYYY");
                }
            },

        ],
        []
    );
    const admincolumns = useMemo(

        () => [
            {
                Header: 'First Name',
                accessor: 'firstName'
            },
            {
                Header: 'Last Name',
                accessor: 'lastName'
            },
            {
                Header: 'Phone',
                accessor: 'phoneNumber'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'User ID',
                id: 'userid1',
                accessor: 'userid',
                Cell: (id) => {
                    return (
                        <div>
                            <span>
                                <a href={`/dashboard/walletcard/viewwallet/${id.value}`}
                                    rel="tooltip"
                                    title="View"
                                >
                                    {id.value}
                                </a></span>&nbsp;&nbsp;
                            {/* <span>
                          <a href={`/dashboard/walletcard/updatewallet/${id.value}`}
                            rel="tooltip"
                            title="Edit"
                          >
                          <FaEdit />
                          </a></span> */}


                        </div>


                    );
                },
            },
            {
                Header: 'Available Wallet Amount',
                accessor: 'amount'
            },
            {
                Header: 'Wallet Type',
                accessor: 'vendorId'
            },
            {
                Header: 'Wallet Status',
                id: "walletStatus",
                accessor: d => {
                    return d.walletStatus ? <span className="text-danger">Inactive</span> : <span className="text-success">Active</span>
                }
            },
            {
                Header: 'Wallet Created Date',
                id: 'registerat',
                accessor: d => {
                    return moment(d.registerat)
                        .local()
                        .format("DD-MM-YYYY");
                }
            },
            {
                Header: 'Actions',
                accessor: 'userid',
                Cell: (userid) => {
                    return (
                        <div>
                            <span>
                                <TiTick
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => activateUserWallet(userid.value)}
                                    title="Activate"
                                />
                            </span>
                            &nbsp;&nbsp;
                            <span>
                                <AiOutlineCloseCircle
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => deactivateUserWallet(userid.value)}
                                    title="De-Activate"
                                />
                            </span>
                        </div>


                    );
                },
            }
        ],
        []
    );


    return (
        <div className="container-fluid">


            <Row className="mt-4">
                <Col>

                    <Row>

                        <Col md={12} sm={12}>
                            <div className="row justify-content-between float-right">
                                <div className="row justify-content-center">
                                    <div className="col-auto claender">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            selectsStart
                                            startDate={startDate}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="col-auto">
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            showDisabledMonthNavigation
                                            dateFormat="dd/MM/yyyy"

                                        />
                                    </div>
                                </div>
                                <div className="csvbutton">
                                    <button className="btn btn-sm" onClick={loadNewData}><span style={{ fontSize: "16px", color: "#676767", fontWeight: "600" }}>GO</span></button>
                                </div>

                                {data != undefined ? <CSVLink data={data}
                                    filename={startDate.toISOString().substr(0, 10) + "_" + endDate.toISOString().substr(0, 10) + `.csv`}
                                    target="_blank"
                                >
                                    <i className="fas fa-2x fa-file-csv"></i>
                                </CSVLink> : <i className="fas fa-2x fa-file-csv"></i>
                                }



                            </div>
                        </Col>
                    </Row>

                    {role === "Admin" ?
                        <div>
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "250px",
                            }}>Loading data...</h3> :
                                data && data.length > 0 ?

                                    (<div> <Table data={data} columns={admincolumns}></Table></div>)
                                    :
                                    (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h3 className="loader">No data found</h3></div>)
                            }

                        </div>
                        : <div>
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "250px",
                            }}>Loading data...</h3> :
                                data && data.length > 0 ?

                                    (<div> <Table data={data} columns={columns}></Table></div>)
                                    :
                                    (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h3 className="loader">No data found</h3></div>)
                            }

                        </div>}
                    <div>
                        {props.loading ? <h3 style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "250px",
                        }}>Loading data...</h3> :
                            data && data.length > 0 ?

                                (<div> <Table data={data} columns={columns}></Table></div>)
                                :
                                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h3 className="loader">No data found</h3></div>)
                        }

                    </div>
                </Col>
            </Row>

        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loading: state.wallet.isLoading,
        wallets: state.wallet.fetchAll,
        cusres: state.wallet.updatecust,
        walletstatus: state.wallet.activateInactivateWallet
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getWallet: (start, end) => dispatch(allCustomerWalletAction(start, end)),
        updateCustomer: (userid, amount, id) => dispatch(updateCustomerWalletAction(userid, amount, id)),
        activateDeactivateWallet: (userid, status) => dispatch(walletActivateInactivateAction(userid, status))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(WalletList)