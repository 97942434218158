import { FETCH_ORGANISATION_EXISTS, PRICE_URL, FETCH_REVENUE_SHARE, SAVE_ORG_API, FETCH_ALL_ORG_API, FETCH_SINGLE_ORG_TARIFF, UPDATE_ORG_API, SAVE_ORG_PROMOTION_CODE, FETCH_ORG_PROMOTION_CODE, SAVE_ORG_CORPORATE_CODE, FETCH_ORG_CORPORATE_CODE, FETCH_ACTIVE_ORG_API, FETCH_ALL_ORG_PERCENTAGE_API, ACTIVATE_PROMOTION_API, ACTIVATE_PROMOTION, DEACTIVATE_PROMOTION, ACTIVATE_CORPORATE_CODE, DEACTIVATE_CORPORATE_CODE, UPDATE_PROMOTION_CODE, UPDATE_CORPORATE_CODE } from "../../components/config/config";

export const activatePromotion = (promotion_code) => {
    console.log("promotion_code", promotion_code);
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        return fetch(PRICE_URL + ACTIVATE_PROMOTION + "?promotion_code=" + promotion_code, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'ACTIVATE_PROMOTIONS', payload: data })
        })
    }
}
export const activateCORPCODE = (corporate_code) => {
    console.log("activateCORPCODE", corporate_code)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        return fetch(PRICE_URL + ACTIVATE_CORPORATE_CODE + "?corporate_code=" + corporate_code, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'ACTIVATE_CORPORATE_CODE', payload: data })
        })
    }
}
export const deactivatePromotion = (promotion_code) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        return fetch(PRICE_URL + DEACTIVATE_PROMOTION + "?promotion_code=" + promotion_code, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'DEACTIVATE_PROMOTION', payload: data })
        })
    }
}
export const deactivateCORPCODE = (corporate_code) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        return fetch(PRICE_URL + DEACTIVATE_CORPORATE_CODE + "?corporate_code=" + corporate_code, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'DEACTIVATE_CORPORATE_CODE', payload: data })
        })
    }
}

export const saveOrganisationAction = (data) => {
    const { name, address, client_revenue_share, regional_revenue_share, self_revenue_share, phone, status, isfleet, per_unit_price, stripe_account, email, city, state, country, Zipcode } = data;
    const { token, role, userid, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(per_unit_price)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        return fetch(PRICE_URL + SAVE_ORG_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "name": name.trim(),
                'address': address,
                'phone': phone,
                "email": email,
                "isfleet": isfleet,
                "per_unit_price": per_unit_price,
                "stripe_account": stripe_account,
                "city": city,
                "state": state,
                "country": country,
                "created_by": userid,
                "postal_code": Zipcode,
                "status": status,
                "role": role,
                "organisation": organisation,
                "client_revenue_share": client_revenue_share.toString(),
                "regional_revenue_share": regional_revenue_share.toString(),
                "self_revenue_share": self_revenue_share.toString(),
            })
        }).then(resp => resp.json()).then(data => {
            // console.log("action data",data);
            dispatch({ type: 'SAVE_ORG', payload: data })
        })
    }
}

export const updateOrganisationAction = (id, data) => {
    const { name, per_unit_price, stripe_account, address, phone, isfleet, client_revenue_share, self_revenue_share, regional_revenue_share, status, email, city, state, country, Zipcode, createdat, role, organisation } = data;
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log("address from action", address)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        return fetch(PRICE_URL + UPDATE_ORG_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "id": id,
                "name": name.trim(),
                'address': address,
                'phone': phone,
                "email": email,
                "isfleet": isfleet,
                "per_unit_price": per_unit_price,
                "stripe_account": stripe_account,
                "city": city,
                "state": state,
                "country": country,
                "client_revenue_share": client_revenue_share,
                "self_revenue_share": self_revenue_share,
                "regional_revenue_share": regional_revenue_share,
                "status": status,
                "postal_code": Zipcode,
                "createdat": createdat,
                "role": role,
                "organisation": organisation

            })
        }).then(resp => resp.json()).then(data => {
            // console.log("data from action", data);
            dispatch({ type: 'UPDATE_ORG', payload: data })
        })
    }
}

export const fetchRevenueShare = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        return fetch(PRICE_URL + FETCH_REVENUE_SHARE, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'FETCH_REVENUE_SHARE', payload: data })
        })
    }
}

export const allOrgTariffAction = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        return fetch(PRICE_URL + FETCH_ACTIVE_ORG_API + "?role=" + role + "&organisation=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'LOAD_ACTIVE_ORG_LIST', payload: data })
        })
    }
}
export const allOrglist = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        return fetch(PRICE_URL + FETCH_ALL_ORG_API + "?role=" + role + "&organisation=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'LOAD_ALL_ORG_LIST', payload: data })
        })
    }
}
export const allOrgPercentageApi = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        return fetch(PRICE_URL + FETCH_ALL_ORG_PERCENTAGE_API + "?role=" + role + "&organisation=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'FETCH_ALL_ORG_Percentage', payload: data })
        })
    }
}

export const SingleOrgTariffAction = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        return fetch(PRICE_URL + FETCH_SINGLE_ORG_TARIFF + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'SINGLE_ORG_TARIFF', payload: data })
        })
    }
}



export const saveOrganisationPromotionCode = (data, startdate, enddate) => {
    const { promotion_code, discount, promotion_name } = data;
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        return fetch(PRICE_URL + SAVE_ORG_PROMOTION_CODE, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "promotion_code": promotion_code,
                'startdate': startdate,
                'enddate': enddate,
                "discount": discount,
                "promotion_name": promotion_name,
                'role': role,
                "organisation": organisation
            })
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'SAVE_ORG_PROMOTION_CODE', payload: data })
        })
    }
}
export const updatePromotion = (data, startdate, enddate) => {
    const { promotion_code, discount, promotion_name } = data;
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        return fetch(PRICE_URL + UPDATE_PROMOTION_CODE, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "promotion_code": promotion_code,
                'startdate': startdate,
                'enddate': enddate,
                "discount": discount,
                "promotion_name": promotion_name,
                'role': role,
                "organisation": organisation
            })
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'UPDATE_PROMOTION', payload: data })
        })
    }
}

export const allOrgpromotionAction = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        return fetch(PRICE_URL + FETCH_ORG_PROMOTION_CODE + "?role=" + role + "&organisation=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'FETCH_ORG_PROMOTION_CODE', payload: data })
        })
    }
}
export const saveCorporateCode = (data, startdate, enddate, amount, unit) => {
    // console.log(startdate,enddate)
    const { corporate_code, code_name, tariff, assignedoperator } = data;
    const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
    // console.log(PRICE_URL + SAVE_ORG_CORPORATE_CODE)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        return fetch(PRICE_URL + SAVE_ORG_CORPORATE_CODE, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "corporate_code": corporate_code,
                'startdate': startdate,
                'enddate': enddate,
                "code_name": code_name,
                'tariff': tariff,
                "amount": Number(amount),
                "unit": unit,
                'role': role,
                "organisation": organisation,
                "assignedoperator": assignedoperator
            })
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'SAVE_ORG_CORPORATE_CODE', payload: data })
        })
    }
}
export const updateCorporateCode = (data, startdate, enddate) => {
    // console.log("start date and end date from action",startdate,enddate)
    const { corporate_code, code_name, tariff, assignedoperator } = data;
    const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
    // console.log(PRICE_URL + SAVE_ORG_CORPORATE_CODE)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        return fetch(PRICE_URL + UPDATE_CORPORATE_CODE, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "corporate_code": corporate_code,
                'startdate': startdate,
                'enddate': enddate,
                "code_name": code_name,
                'tariff': tariff,
                'role': role,
                "organisation": organisation,
                "assignedoperator": assignedoperator
            })
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'UPDATE_CORPCODE', payload: data })
        })
    }
}

export const allCorporateCodeAction = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        return fetch(PRICE_URL + FETCH_ORG_CORPORATE_CODE + "?role=" + role + "&organisation=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'FETCH_ORG_CORPORATE_CODE', payload: data })
        })
    }
}

export const fetchOrganisationName = (name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_ORG', payload: '' })
        return fetch(PRICE_URL + FETCH_ORGANISATION_EXISTS + "?name=" + name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'FETCH_ALL_ORG_NAME', payload: data })
        })
    }
}