import { ACTIVATE_LOYALTY_REDEEM, ACTIVATE_LOYALTY_RULE, ACTIVATE_PROGRAM_API, DEACTIVATE_LOYALTY_REDEEM, DEACTIVATE_LOYALTY_RULE, DEACTIVATE_PROGRAM_API, FETCH_ALL_CARD_API, FETCH_ALL_CUSTOMER_HOLI, FETCH_ALL_LOYALTY_PROGRAM, FETCH_ALL_LOYALTY_RULE, FETCH_ALL_REDEEM_API, FETCH_MONTHLY_GRAPH_DATA_API, FETCH_SINGLE_CUSTOMER_API, FETCH_SINGLE_CUSTOMER_REDEEMPTION_DATA_API, FETCH_SINGLE_GRAPH_API, FETCH_SINGLE_LOYALTY_PROGRAM, FETCH_SINGLE_LOYALTY_RULE, FETCH_SINGLE_REDEEM_API, FETCH_TOTAL_CONSUMPTION_CARD_API, LOYALTY_URL, REDEEM_RULE_TYPE_DROPDOWN, RULE_TYPE_DROPDOWN, SAVE_LOYALTY_PROGRAM, SAVE_LOYALTY_REDEEM_API, SAVE_LOYALTY_RULE, UPDATE_LOYALTY_PROGRAM, UPDATE_LOYALTY_REDEEM, UPDATE_LOYALTY_RULE } from "../../components/config/config";

// rule
export const saveRule = (record) => {
    const { rule_name, ruleGroup } = record;
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(record)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_LOYALTY', payload: '' })
        return fetch(LOYALTY_URL + SAVE_LOYALTY_RULE, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "role": role,
                "operator": organisation,
                "rule_name": rule_name,
                "components": ruleGroup
            })
        }).then(resp => resp.json()).then(data => {
            // console.log("CREATE_RULE", data)
            dispatch({ type: 'SAVE_LOYALTY_RULE', payload: data })
        })
    }
}
export const updateRuleApi = (record) => {
    const { rule_name, ruleGroup } = record;
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(token)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_LOYALTY', payload: '' })
        return fetch(LOYALTY_URL + UPDATE_LOYALTY_RULE, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "role": role,
                "operator": organisation,
                "rule_name": rule_name,
                "components": ruleGroup
            })
        }).then(resp => resp.json()).then(data => {
            // console.log("CREATE_RULE", data)
            dispatch({ type: 'UPDATE_LOYALTY_RULE', payload: data })
        })
    }
}
export const fetchAllRuleApi = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(organisation)
    const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_LOYALTY', payload: '' })
        return fetch(LOYALTY_URL + FETCH_ALL_LOYALTY_RULE + "?role=" + role + "&operator=" + operator, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("ALL RULE DATA action",data)
            dispatch({ type: 'FETCH_ALL_LOYALTY_RULE', payload: data })
        })
    }
}
export const fetchSingleRuleApi = (rule_name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log(token)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_LOYALTY', payload: '' })
        return fetch(LOYALTY_URL + FETCH_SINGLE_LOYALTY_RULE + "?rule_name=" + rule_name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("Single RULE DATA action",data)
            dispatch({ type: 'FETCH_SINGLE_LOYALTY_RULE', payload: data })
        })
    }
}
export const activateRuleApi = (rule_name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log(organisation)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_LOYALTY', payload: '' })
        return fetch(LOYALTY_URL + ACTIVATE_LOYALTY_RULE + "?rule_name=" + rule_name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("Activate action",data)
            dispatch({ type: 'ACTIVATE_LOYALTY_RULE', payload: data })
        })
    }
}
export const deactivateRuleApi = (rule_name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log(organisation)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_LOYALTY', payload: '' })
        return fetch(LOYALTY_URL + DEACTIVATE_LOYALTY_RULE + "?rule_name=" + rule_name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("Deactivate action",data)
            dispatch({ type: 'DEACTIVATE_LOYALTY_RULE', payload: data })
        })
    }
}
export const fetchRuleDropdownApi = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(organisation)
    // const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_LOYALTY', payload: '' })
        return fetch(LOYALTY_URL + RULE_TYPE_DROPDOWN, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("FETCH_RULE_TYPE_DROPDOWN_API action",data)
            dispatch({ type: 'FETCH_RULE_TYPE_DROPDOWN_API', payload: data })
        })
    }
}

// program api

export const saveProgramApi = (record, startdate, enddate) => {
    const { rule_name, program_name } = record;
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(token)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_LOYALTY', payload: '' })
        return fetch(LOYALTY_URL + SAVE_LOYALTY_PROGRAM, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "role": role,
                "operator": organisation,
                "rule_name": rule_name,
                "program_name": program_name,
                "startdate": startdate,
                "enddate": enddate,
            })
        }).then(resp => resp.json()).then(data => {
            // console.log("save program action", data)
            dispatch({ type: 'SAVE_LOYALTY_PROGRAM', payload: data })
        })
    }
}

export const fetchAllProgramApi = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: "LOAD_LOYALTY", payload: '' })
        return fetch(LOYALTY_URL + FETCH_ALL_LOYALTY_PROGRAM + "?role=" + role + "&operator=" + operator, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(resp => resp.json()).then(data => {
            // console.log("fetch all program", data)
            dispatch({ type: 'FETCH_ALL_LOYALTY_PROGRAM', payload: data })
        })
    }
}

export const fetchSingleProgramApi = (program_name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log(token)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_LOYALTY', payload: '' })
        return fetch(LOYALTY_URL + FETCH_SINGLE_LOYALTY_PROGRAM + "?program_name=" + program_name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("Single Program DATA action",data)
            dispatch({ type: 'FETCH_SINGLE_LOYALTY_PROGRAM', payload: data })
        })
    }
}
export const updateProgramApi = (record, status, startdate, enddate) => {
    const { rule_name, program_name } = record;
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(token)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_LOYALTY', payload: '' })
        return fetch(LOYALTY_URL + UPDATE_LOYALTY_PROGRAM, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "role": role,
                "operator": organisation,
                "rule_name": rule_name,
                "startdate": startdate,
                "enddate": enddate,
                "program_name": program_name,
                "status": status,
            })
        }).then(resp => resp.json()).then(data => {
            // console.log("UPDATE_LOYALTY_PROGRAM action", data)
            dispatch({ type: 'UPDATE_LOYALTY_PROGRAM', payload: data })
        })
    }
}

export const activateProgramApi = (program_name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log(organisation)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_LOYALTY', payload: '' })
        return fetch(LOYALTY_URL + ACTIVATE_PROGRAM_API + "?program_name=" + program_name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("Activate action",data)
            dispatch({ type: 'ACTIVATE_LOYALTY_PROGRAM', payload: data })
        })
    }
}
export const deactivateProgramApi = (program_name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log(organisation)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_LOYALTY', payload: '' })
        return fetch(LOYALTY_URL + DEACTIVATE_PROGRAM_API + "?program_name=" + program_name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("Activate action",data)
            dispatch({ type: 'DEACTIVATE_LOYALTY_PROGRAM', payload: data })
        })
    }
}

// Redeem actions

export const saveRedeemApi = (record, startdate, enddate) => {
    // console.log(record,startdate,enddate)
    const { redeem_name, redeemGroup } = record;
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(token)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_LOYALTY', payload: '' })
        return fetch(LOYALTY_URL + SAVE_LOYALTY_REDEEM_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "role": role,
                "operator": organisation,
                "redeem_name": redeem_name,
                "components": redeemGroup,
                "startdate": startdate,
                "enddate": enddate,
            })
        }).then(resp => resp.json()).then(data => {
            console.log("save redeem action", data)
            dispatch({ type: 'SAVE_LOYALTY_REDEEM', payload: data })
        })
    }
}
export const fetchAllRedeemApi = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: "LOAD_LOYALTY", payload: '' })
        // console.log(LOYALTY_URL + FETCH_ALL_REDEEM_API + "?role=" + role + "&operator=" + operator)
        return fetch(LOYALTY_URL + FETCH_ALL_REDEEM_API + "?role=" + role + "&operator=" + operator, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(resp => resp.json()).then(data => {
            // console.log("fetch all program", data)
            dispatch({ type: 'FETCH_ALL_REDEEM', payload: data })
        })
    }
}
export const fetchSingleRedeemApi = (redeem_name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log(token)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_LOYALTY', payload: '' })
        return fetch(LOYALTY_URL + FETCH_SINGLE_REDEEM_API + "?redeem_name=" + redeem_name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("Single redeem DATA action",data)
            dispatch({ type: 'FETCH_SINGLE_REDEEM', payload: data })
        })
    }
}
export const updateRedeemApi = (record, status, startdate, enddate) => {
    // console.log(record,startdate,enddate)
    const { redeem_name, redeemGroup } = record;
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(token)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_LOYALTY', payload: '' })
        return fetch(LOYALTY_URL + UPDATE_LOYALTY_REDEEM, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "role": role,
                "operator": organisation,
                "redeem_name": redeem_name,
                "components": redeemGroup,
                "startdate": startdate,
                "enddate": enddate,
                "status": status,
            })
        }).then(resp => resp.json()).then(data => {
            // console.log("update redeem action", data)
            dispatch({ type: 'UPDATE_LOYALTY_REDEEM', payload: data })
        })
    }
}
export const activateRedeemApi = (redeem_name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log(organisation)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_LOYALTY', payload: '' })
        return fetch(LOYALTY_URL + ACTIVATE_LOYALTY_REDEEM + "?redeem_name=" + redeem_name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("Activate action",data)
            dispatch({ type: 'ACTIVATE_LOYALTY_REDEEM', payload: data })
        })
    }
}
export const deactivateRedeemApi = (redeem_name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    // console.log(organisation)
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_LOYALTY', payload: '' })
        return fetch(LOYALTY_URL + DEACTIVATE_LOYALTY_REDEEM + "?redeem_name=" + redeem_name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("Activate action",data)
            dispatch({ type: 'DEACTIVATE_LOYALTY_REDEEM', payload: data })
        })
    }
}
export const fetchRedeemRuleTypeDropdownApi = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // console.log(organisation)
    // const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_LOYALTY', payload: '' })
        return fetch(LOYALTY_URL + REDEEM_RULE_TYPE_DROPDOWN, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("FETCH_REDEEM_RULE_DROPDOWN_API action",data)
            dispatch({ type: 'FETCH_REDEEM_RULE_DROPDOWN_API', payload: data })
        })
    }
}

// customer redemption

export const fetchAllCardData = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: "LOAD_LOYALTY", payload: '' })
        return fetch(LOYALTY_URL + FETCH_ALL_CARD_API + "?operator=" + operator, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(resp => resp.json()).then(data => {
            // console.log("fetch all program", data)
            dispatch({ type: 'FETCH_ALL_CARD_DATA', payload: data })
        })
    }
}
export const fetchMonthlyGraphData = (startdate, enddate) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: "LOAD_LOYALTY", payload: '' })
        return fetch(LOYALTY_URL + FETCH_MONTHLY_GRAPH_DATA_API + "?operator=" + operator + "&start=" + startdate + "&end=" + enddate, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(resp => resp.json()).then(data => {
            // console.log("FETCH_MONTHLY_GRAPH_DATA", data)
            dispatch({ type: 'FETCH_MONTHLY_GRAPH_DATA', payload: data })
        })
    }
}
export const fetchAllCustomerData = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: "LOAD_LOYALTY", payload: '' })
        return fetch(LOYALTY_URL + FETCH_ALL_CUSTOMER_HOLI + "?operator=" + operator, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(resp => resp.json()).then(data => {
            // console.log("FETCH_ALL_CUSTOMER_API", data)
            dispatch({ type: 'FETCH_ALL_CUSTOMER_API', payload: data })
        })
    }
}
export const fetchSingleGraphApi = (userid, startdate, enddate) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: "LOAD_LOYALTY", payload: '' })
        // console.log(LOYALTY_URL + FETCH_SINGLE_GRAPH_API +"?userid=" + userid + "&start=" + startdate + "&end=" + enddate)
        return fetch(LOYALTY_URL + FETCH_SINGLE_GRAPH_API + "?userid=" + userid + "&start=" + startdate + "&end=" + enddate, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(resp => resp.json()).then(data => {
            // console.log("FETCH_GRAPH_API", data)
            dispatch({ type: 'FETCH_SINGLE_GRAPH_API', payload: data })
        })
    }
}
export const fetchSingleCustomerTotalConsumptionApi = (userid) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: "LOAD_LOYALTY", payload: '' })
        return fetch(LOYALTY_URL + FETCH_TOTAL_CONSUMPTION_CARD_API + "?userid=" + userid, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(resp => resp.json()).then(data => {
            // console.log("FETCH_TOTAL_CONSUMPTION_CARD_API", data)
            dispatch({ type: 'FETCH_TOTAL_CONSUMPTION_CARD_API', payload: data })
        })
    }
}
export const fetchSingleCustomerApi = (userid) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: "LOAD_LOYALTY", payload: '' })
        return fetch(LOYALTY_URL + FETCH_SINGLE_CUSTOMER_REDEEMPTION_DATA_API + "?userid=" + userid, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(resp => resp.json()).then(data => {
            // console.log("FETCH_SINGLE_CUSTOMER_DATA_API", data)
            dispatch({ type: 'FETCH_SINGLE_CUSTOMER_DATA_API', payload: data })
        })
    }
}
