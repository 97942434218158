import React, { useMemo } from "react";
import { connect } from "react-redux";
import "../reacttable/reactTable.css";
import Table from "../reacttable/table";
import { useTranslation } from "react-i18next";

function RemoteEVSEListComponent(props) {
  const { data } = props;
  // console.log("props", props);
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Status",
        accessor: "status",
      },

      {
        Header: "Commission Date",
        id: "date",
        accessor: (d) => {
          const date = new Date(d.date);
          const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
          return date.toLocaleDateString('en-GB', options);
        },
      },

      {
        Header: "Active/Inactive",
        accessor: "publish",
        Cell: (publish) => {
          // console.log(publish.value)
          return (
            <div>
              <span>
                {publish.value === true ? (
                  <span className="text-success">Active</span>
                ) : (
                  <span className="text-danger">Inactive</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "stationid",
        Cell: (rowinfo) => {
          // console.log("rowinfo",rowinfo)
          const { value, row } = rowinfo;
          const { original } = row;

          if ((original.status === "Available" || original.status === "Maintenance" || original.status === "Inoperative" || original.status === "In use")) {
            return (
              <div>
                <span>
                  <a
                    href={`/dashboard/charger/commands/${value}`}
                    rel="tooltip"
                    title="Commands"
                  >
                    <button className="btn commandButtons" type="button">
                      {t("Manage EVSE")}
                    </button>
                  </a>
                </span>
              </div>
            );
          } else {
            return <span>{t("Station is not Available")}</span>;
          }
        },
      },
    ],
    []
  );
  // console.log(props.loading)
  return (
    <div>

      {data && data.length > 0 ? (
        <div>
          <Table data={data} columns={columns}></Table>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3 className="loader">{t("No data found")}</h3>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    // activate: state.evse.activatestation,
    // deactivate: state.evse.deactivatestation,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // activateStation: (stationid) => dispatch(activateStation(stationid)),
    // deactivateStation: (stationid) => dispatch(deactivateStation(stationid)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RemoteEVSEListComponent);
