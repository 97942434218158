import React, { useEffect } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import "react-dates/initialize";
import PureBreadcrumbs from "../../breadcrums";
import { useState, useMemo } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { allRoamingPartnerPartnerID, fetchExternalTransactionListAction } from "../../../../store/actions/ocpiAction";
import Table from "../../reacttable/table";
import moment from "moment";


function RoamingReport(props) {
    const { t } = useTranslation();
    const [org, setOrg] = useState("All");
    const currency = process.env.REACT_APP_CURRENCY;

    useEffect(() => {
        props.allPartnerPartyId();
        props.getTransactionList(org);
    }, [org])

    const handleChange = (e) => {
        const data = e.target.value;
        setOrg(data);
    };
    const data = props.list && props.list.data
    const columns = useMemo(
        () => [
            { Header: "ID", accessor: "charging_id" },
            { Header: "First Name", accessor: "firstname" },
            { Header: "Last Name", accessor: "lastname" },
            { Header: "User Name", accessor: "userid" },
            { Header: "EVSE UID", accessor: "evse_uid" },

            { Header: "Location", accessor: "location_id", id: "location_idd" },
            {
                Header: "Start Date",
                id: "start_date_time",
                accessor: row => moment(row.start_date_time).format('DD-MM-YYYY'),
            },
            {
                Header: "End Date Date",
                id: "end_date_time",
                accessor: row => moment(row.end_date_time).format('DD-MM-YYYY'),
            },
            { Header: "Connector ID", accessor: "connector_id" },
            { Header: "kWh", accessor: "kwh" },
            { Header: "Cost Excluding Vat", accessor: "excl_vat" },
            { Header: "Cost Including Vat", accessor: "incl_vat" },
            { Header: "Status", accessor: "status" },
        ],
        []
    );

    return (
        <Container fluid>
            <PureBreadcrumbs />
            {/* Ev Report Cards Start here */}
            <Row className="mt-2">
                <Col className="mt-2" xs={12} md={3}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>Total Session</Card.Text>
                            <Card.Title>
                                <b>
                                    {/* {props.all.total_session != 0 ? props.all.total_session : 0} */}
                                    0
                                </b>
                            </Card.Title>
                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-60px",
                                    marginRight: "-8px",
                                }}
                            >
                                <div style={{ width: "15%" }}>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL + "/images/reports/sessions.svg"
                                        }
                                        style={{ "width": "60px" }}
                                        alt=""
                                    />
                                </div>
                            </span>
                        </div>
                    </Card>
                    <br />
                </Col>
                <Col className="mt-2" xs={12} md={3}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>Total Energy</Card.Text>
                            <Card.Title>
                                {/* <b>{props.all && props.all.total_energy}</b> */}
                                0
                            </Card.Title>
                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-60px",
                                    marginRight: "-8px",
                                }}
                            >
                                <div style={{ width: "10%" }}>
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/reports/energy.svg"}
                                        alt=""
                                        style={{ "width": "40px" }}
                                    />
                                </div>
                            </span>
                        </div>
                    </Card>
                    <br />
                </Col>
                <Col className="mt-2" xs={12} md={3}>
                    <Card className="customercard">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>Total Revenue</Card.Text>
                            <Card.Title>
                                {/* <b>{props.all && props.all.total_revenue}</b> */}
                                0
                            </Card.Title>
                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-65px",
                                    marginRight: "-8px",
                                }}
                            >
                                {<div style={{ fontSize: "3.5rem", paddingRight: "11px", color: "#2ea8cc80", marginTop: "-7px" }}>
                                    {currency}
                                </div>}
                            </span>
                        </div>
                    </Card>
                </Col>
                <Col className="mt-2" xs={12} md={3}>
                    <Card className="customercard ">
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "14px" }}>
                                Total Sessions Completed
                            </Card.Text>
                            <Card.Title>
                                {/* <b>{props.all && props.all.total_completed}</b> */}
                                0
                            </Card.Title>
                            <span
                                style={{
                                    float: "right",
                                    marginTop: "-50px",
                                    marginRight: "-8px",
                                }}
                            >
                                <div style={{ width: "10%" }}>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/reports/sessionsstats.svg"
                                        }
                                        alt=""
                                        style={{ "width": "70px" }}
                                    />
                                </div>
                            </span>
                        </div>
                    </Card>
                    <br />
                </Col>
            </Row>
            {/* Ev Report Card Ends here */}
            <Row className="mt-2">
                <Col>
                    <Card className="customercard">
                        <Card.Header>
                            <Row>
                                <Col md={2} sm={12}>
                                    Transaction Report
                                </Col>
                                <Col md={10} sm={12}>
                                    <div className="row justify-content-end">
                                        <div style={{ paddingRight: "10px" }} >
                                            <select style={{ borderRadius: "3px", borderColor: "#a7a6a6", width: "auto" }} className={``} onChange={(e) => handleChange(e)}>
                                                <option value={"All"} >
                                                    {t("All")}
                                                </option>
                                                {props.partyidlist && props.partyidlist.data?.map((item, k) => (
                                                    <option key={item.id} value={item.party_id}>{item.partner_name}</option>

                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        {data && data.length > 0 ? (
                            <div>
                                <Table data={data} columns={columns}></Table>
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <h3 className="loader">{t("No data found")}</h3>
                            </div>
                        )}
                    </Card>
                </Col>
            </Row>
        </Container >
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.ocpi.isLoading,
        list: state.ocpi.fetchExternalTransactionList,
        partyidlist: state.ocpi.allpartyid,

    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        getTransactionList: (party_id) => dispatch(fetchExternalTransactionListAction(party_id)),
        allPartnerPartyId: () => dispatch(allRoamingPartnerPartnerID()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoamingReport);
