import React, { useMemo } from "react";
import { connect } from "react-redux";
import "../../reacttable/reactTable.css";
import Table from "../../reacttable/table";
import { FaEdit, FaEye } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle, AiOutlineQrcode } from "react-icons/ai";
import {
  activateStation,
  deactivateStation,
  deleteStationApi,
} from "../../../../store/actions/evseAction";
import moment from "moment";
import { useTranslation } from "react-i18next";

function EVSEList(props) {
  const { t } = useTranslation();
  // const data = props.list && props.list.data;
  // console.log("evse list",data);
  const filterDiscoveryData = props.list && props.list.data?.filter((item) => item.status !== "Discovery");
  console.log("filterDiscoveryData", filterDiscoveryData);

  const activateStation = (stationid) => {
    // console.log(stationid);
    props.activateStation(stationid);
    window.location.reload();
  };

  const deactivateStation = (stationid) => {
    // console.log(stationid);
    props.deactivateStation(stationid);
    window.location.reload();
  };

  // const deleteStation = (stationid) => {
  //   // console.log(stationid);
  //   props.deleteStation(stationid);
  //   window.location.reload();
  // };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Organisation",
        accessor: "operator.name",
      },
      {
        Header: "Is it free charging ?",
        accessor: "free_charging",
        Cell: (free_charging) => {
          // console.log(publish.value)
          return (
            <div>
              <span>
                {free_charging.value === true ? (
                  <span className="text-success">{t("Yes")}</span>
                ) : (
                  <span className="text-danger">{t("No")}</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
      },

      {
        Header: "Commission Date",
        id: "date",
        accessor: (d) => {
          return moment(d.date, "DD-MMM-YYYY").format("DD-MM-YYYY");
        },
      },
      {
        Header: "Station QR Code",
        id: "qrcode",
        accessor: (d) => {
          return (
            <center>
              {d.qrcode !== '' ? d.qrcode && <a
                href={`https://api.qrserver.com/v1/create-qr-code/?size=600x600&data=${d.qrcode !== '' ? d.qrcode : ''}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <AiOutlineQrcode size="25px" color="black" />
              </a> : ''}
            </center>
          );
        },
      },
      {
        Header: "Active/Inactive",
        accessor: "publish",
        Cell: (publish) => {
          // console.log(publish.value)
          return (
            <div>
              <span>
                {publish.value === true ? (
                  <span className="text-success">{t("Active")}</span>
                ) : (
                  <span className="text-danger">{t("Inactive")}</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "stationid",
        Cell: (stationid) => {
          return (
            <div>
              <span>
                <a
                  href={`/dashboard/viewsingleevse/${stationid.value}`}
                  rel="tooltip"
                  title="View"
                >
                  <FaEye />
                </a>
              </span>
              &nbsp;&nbsp;
              <span>
                <a
                  href={`/dashboard/updateevse/${stationid.value}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEdit />
                </a>
              </span>
              &nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <TiTick
                  onClick={() => activateStation(stationid.value)}
                  title="Activate"
                />
              </span>
              &nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <AiOutlineCloseCircle
                  onClick={() => deactivateStation(stationid.value)}
                  title="De-Activate"
                />
              </span>
              {/* &nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <i className="fa fa-trash text-danger"
                  onClick={() => deleteStation(stationid.value)}
                  title="Delete">
                </i>
              </span> */}
            </div>
          );
        },
      },
    ],
    []
  );
  // console.log(props.loading)
  return (
    <div>
      {
        filterDiscoveryData && filterDiscoveryData.length > 0 ? (
          <div>
            <Table data={filterDiscoveryData} columns={columns}></Table>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3>{t("No data found")}</h3>
          </div>
        )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.evse.isEvseLoading,
    activate: state.evse.activatestation,
    deactivate: state.evse.deactivatestation,
    deleteStationRes: state.evse.stationDelete,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    activateStation: (stationid) => dispatch(activateStation(stationid)),
    deactivateStation: (stationid) => dispatch(deactivateStation(stationid)),
    deleteStation: (stationid) => dispatch(deleteStationApi(stationid)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EVSEList);
