import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import "../../reacttable/reactTable.css";
import Table from "../../reacttable/table";
import { activateRfid, allRFIDAction, deactivateRfid } from "../../../../store/actions/customerActions";
import { FaEdit, FaEye } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import moment from "moment";

function RFIDList(props) {
  // const { t } = useTranslation();
  // const { data } = props;
  useEffect(() => {
    props.all();
  }, []);


  const data = props.list && props.list.rfids;
  // console.log("rfid list props", data);

  const activateStation = (id) => {
    // console.log(id);
    props.activateRFID(id);
  };

  if (props.activeRfid.statuscode === 200) {
    window.location.reload();
  }

  const deactivateStation = (id) => {
    // console.log(id);
    props.deactivateRFID(id);

  };

  if (props.deactivateRfid.statuscode === 200) {
    window.location.reload();
  }


  const columns = useMemo(
    () => [
      {
        Header: "RFID Serial Number",
        accessor: "rfid",
      },
      {
        Header: "Commission Date",
        accessor: "commission",
        // accessor: (d) => {
        //   return moment(d.commission).local().format("DD-MM-YYYY");
        // },
      },
      // {
      //   Header: "Station Name",
      //   accessor: "station",
      //   Cell: (station) => {
      //     return (
      //       <div>
      //         <span>
      //           {station.value ? (
      //             <span >{station.value}</span>
      //           ) : (
      //             <span >Open single view</span>
      //           )}
      //         </span>
      //       </div>
      //     );
      //   }
      // },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "kWh",
        accessor: "kwh",
      },
      {
        Header: "Time",
        accessor: "time",
      },
      {
        Header: "Currency",
        accessor: "currency",
      },
      {
        Header: "Is payment collected offline ?",
        accessor: "offline_payment",
        Cell: (offline_payment) => {
          // console.log(publish.value)
          return (
            <div>
              <span>
                {offline_payment.value ? (
                  <span className="text-success">Yes</span>
                ) : (
                  <span className="text-danger">No</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Is Free Charging?",
        accessor: "free_charging",
        Cell: (free_charging) => {
          // console.log(publish.value)
          return (
            <div>
              <span>
                {free_charging.value ? (
                  <span className="text-success">Yes</span>
                ) : (
                  <span className="text-danger">No</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (status) => {
          // console.log(publish.value)
          return (
            <div>
              <span>
                {status.value === "Active" ? (
                  <span className="text-success">Active</span>
                ) : (
                  <span className="text-danger">Inactive</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => {
          const { original } = row;
          const { status, id } = original;
          // console.log("original", original);

          return (
            <div>
              {<span>
                <a
                  href={`/dashboard/viewSingleRfid/${id}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEye />
                </a>
              </span>}
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              {<span>
                <a
                  href={`/dashboard/updaterfid/${id}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEdit />
                </a>
              </span>}
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <TiTick
                  onClick={() => activateStation(id)}
                  title="Activate"
                />
              </span>
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <AiOutlineCloseCircle
                  onClick={() => deactivateStation(id)}
                  title="De-Activate"
                />
              </span>

            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      {props.loading ? <h3 style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "350px",
      }}>Loading data...</h3> :
        data && data.length > 0 ? (
          <div>
            <Table data={data} columns={columns}></Table>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3 className="loader">{(" No data found")}</h3>
          </div>
        )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.customer.isLoading,
    list: state.customer.fetchAllRFID,
    activeRfid: state.customer.activateRfid,
    deactivateRfid: state.customer.deactivateRfid,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: () => dispatch(allRFIDAction()),
    activateRFID: (id) => dispatch(activateRfid(id)),
    deactivateRFID: (id) => dispatch(deactivateRfid(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RFIDList);
