import { number } from "yup"
import { BASE_URL, GET_LOGIN_API, FORGET_PASSWORD_API, GET_SEND_OTP_API, GET_RESEND_OTP_API, VALIDATE_OTP } from "../../components/config/config"

export const authAction = (username, password) => {
  return dispatch => {
    dispatch({ type: 'LOGIN_LOADING', payload: '' })
    return fetch(BASE_URL + GET_LOGIN_API, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "userid": username,
        'password': password
      })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log(data);
        localStorage.setItem('user', JSON.stringify(data));
        dispatch({ type: 'LOGIN_USER', payload: data })
      })
  }
}

export const validateOTP = (data2) => {
  const { userid, otp } = data2
  // console.log("validateOTP", userid,otp);
  return dispatch => {

    dispatch({ type: 'LOGIN_LOADING', payload: '' })
    return fetch(BASE_URL + VALIDATE_OTP, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "userid": userid,
        "otp": otp,
      })
    })
      .then(resp => resp.json())
      .then(data => {
        // console.log("data from validate action otp",data);
        localStorage.setItem("forgotpassword", JSON.stringify(data2))
        dispatch({ type: 'VALIDATE_USER_OTP', payload: data })
      })
  }
}

export const forgetPassword = (record) => {
  const { newpassword } = record;
  const { userid } = JSON.parse(localStorage.getItem("forgotpassword"));
  // console.log(newpassword)
  return dispatch => {
    dispatch({ type: 'LOGIN_LOADING', payload: '' })
    return fetch(BASE_URL + FORGET_PASSWORD_API, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        'userid': userid,
        'password': newpassword
      })
    }).then(resp => resp.json()).then(data => {
      // console.log("Forget Action,data",data)
      dispatch({ type: 'FORGET_USER_PASSWORD', payload: data })
    })
  }
}
export const getSendOTP = (data) => {
  const { userid } = data
  // console.log("userid from action send otp",userid)
  return dispatch => {
    dispatch({ type: 'LOGIN_LOADING', payload: '' })
    return fetch(BASE_URL + GET_SEND_OTP_API + "?userid=" + userid, {
      method: "GET",
      headers: {

        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log("GET_SEND_OTP",data)
      dispatch({ type: 'GET_SEND_OTP', payload: data })
    })
  }
}
export const getReSendOTP = (data) => {
  const { userid } = data
  // console.log("userid from action resend otp",userid)
  return dispatch => {
    dispatch({ type: 'LOGIN_LOADING', payload: '' })
    return fetch(BASE_URL + GET_RESEND_OTP_API + "?userid=" + userid, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log("GET_RESEND_OTP",data)
      dispatch({ type: 'GET_RESEND_OTP', payload: data })
    })
  }
}


export const logoutAction = () => {
  return dispatch => {
    dispatch({ type: 'LOGOUT_USER', payload: {} })
    localStorage.removeItem('user');
  }
}