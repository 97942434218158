import { Card, Row, Col } from "react-bootstrap";
import React from "react";
import Table from "../../../reacttable/table";
import { useTranslation } from "react-i18next";
import { FaEye } from "react-icons/fa";

function ConsumedStationList(props) {
  const { data } = props;
  const { t } = useTranslation();
  // console.log(data);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        id: "1",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Operator",
        accessor: "operator.name",
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => {
          const { original } = row;
          const { name, party_id } = original;
          return (
            <div>
              <span>
                <a
                  href={`/dashboard/viewOcpiLocation/${name}/${party_id}`}
                  rel="tooltip"
                  title="View"
                >
                  <FaEye />
                </a>
              </span>
              &nbsp;&nbsp;
            </div>
          );
        },
      },
    ],
    []
  );

  const loadNewData = () => {
    window.location.reload();
  };
  return (
    <Row>
      <Col>
        <Card className="customercard">
          <Card.Header>
            <Row>
              <div className="table-header">{t("View OCPI")}</div>
            </Row>
          </Card.Header>

          {data && data.length > 0 ? (
            <div>
              <Table data={data} columns={columns}></Table>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3 className="loader">{t("No data found")}</h3>
            </div>
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default ConsumedStationList;
