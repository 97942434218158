const initState = {
    isLoading: false,
    changeAvailability: '',
    resetCharger: '',
    clearCache: '',
    unlockConnector: '',
    getDiagnostic: '',
    triggerMessage: '',
    clearChargingProfile: '',
    getLocalListVersion: '',
    updateFirmware: '',
    getCompositeSchedule: '',
    reservenow: '',
    cancelreserve: '',
    getConfiguration: '',
    changeConfiguration: '',
    ocppLogList: '',
    evseConfigData: '',
    fetchNewConfigData: '',
    saveConfig: '',
    fetchAllOCPPLogsData: '',
    fetchFailedTransaction: '',
    fetchAllFailedTransaction: '',
}
const commandReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOAD_CHARGER_COMMAND':
            return { ...state, isLoading: true }
        case 'TRIGGER_CHANGE_AVAILABILITY':
            return { ...state, changeAvailability: action.payload, isLoading: false };
        case 'TRIGGER_RESET_CHARGER':
            return { ...state, resetCharger: action.payload, isLoading: false };
        case 'TRIGGER_CLEAR_CACHE':
            return { ...state, clearCache: action.payload, isLoading: false };
        case 'TRIGGER_UNLOCK_CONNECTOR':
            return { ...state, unlockConnector: action.payload, isLoading: false };
        case 'TRIGGER_GET_DIAGNOSTIC':
            return { ...state, getDiagnostic: action.payload, isLoading: false };
        case 'TRIGGER_MESSAGE':
            return { ...state, triggerMessage: action.payload, isLoading: false };
        case 'TRIGGER_CLEAR_CHARGING_PROFILE':
            return { ...state, clearChargingProfile: action.payload, isLoading: false };
        case 'TRIGGER_LOCAL_LIST_VERSION':
            return { ...state, getLocalListVersion: action.payload, isLoading: false };
        case 'TRIGGER_UPDATE_FIRMWARE':
            return { ...state, updateFirmware: action.payload, isLoading: false };
        case 'GET_COMPOSITE_SCHEDULE':
            return { ...state, getCompositeSchedule: action.payload, isLoading: false };
        case 'TRIGGER_RESERVE_NOW':
            return { ...state, reservenow: action.payload, isLoading: false };
        case 'TRIGGER_CANCEL_RESERVATION':
            return { ...state, cancelreserve: action.payload, isLoading: false };
        case 'GET_CONFIGURATION':
            return { ...state, getConfiguration: action.payload, isLoading: false };
        case 'CHANGE_CONFIGURATION':
            return { ...state, changeConfiguration: action.payload, isLoading: false };
        case 'OCPP_LOG_EVENT':
            return { ...state, ocppLogList: action.payload, isLoading: false };
        case 'CHARGER_CONFIG_EVENT':
            return { ...state, evseConfigData: action.payload, isLoading: false };
        case 'FETCH_NEW_CONFIG_DATA':
            return { ...state, fetchNewConfigData: action.payload, isLoading: false };
        case 'SAVE_NEW_CONFIG_DATA':
            return { ...state, saveConfig: action.payload, isLoading: false };
        case 'FETCH_ALL_OCPP_LOG_DATA':
            return { ...state, fetchAllOCPPLogsData: action.payload, isLoading: false };
        default:
            return state;
    }
}
export default commandReducer;