import React, { useEffect, useMemo } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
// import Table from "../../reacttable/table";
import moment from "moment";
import DownloadBindRfidDetails from "../../customers/rfids/downloadBindRfidDetails";
import Table from "../../reacttable/table";
import { fetchRfidList } from "../../../../store/actions/customerActions";
import PureBreadcrumbs from "../../breadcrums";
import { FaEye } from "react-icons/fa";

function RfidReport(props) {
    const { t } = useTranslation();
    // console.log("props", props);

    useEffect(() => {
        props.getRfid();
    }, [])

    const data = props.fetchRfidListRes && props.fetchRfidListRes.data;
    // console.log("data", data);

    const columns = useMemo(
        () => [
            {
                Header: "Rfid",
                accessor: "rfid",
            },
            {
                Header: "User Name",
                id: "useridd",
                accessor: "userid",
            },
            {
                Header: "Binded Station Name",
                accessor: "station",
                Cell: ({ row }) => {
                    const station = row.original.station;
                    const rfidStations = row.original.rfid_stations;
                    if (station === 'All') {
                        return 'All';
                    } else if (Array.isArray(rfidStations) && rfidStations.length > 0) {
                        return rfidStations.join(', ');
                    } else {
                        return '';
                    }
                }
            },
            {
                Header: "Is Free Charging?",
                accessor: "free_charging",
                Cell: (free_charging) => {
                    // console.log(publish.value)
                    return (
                        <div>
                            <span>
                                {free_charging.value ? (
                                    <span className="text-success">Yes</span>
                                ) : (
                                    <span className="text-danger">No</span>
                                )}
                            </span>
                        </div>
                    );
                },
            },
            {
                Header: "Created Date",
                id: "registerat",
                accessor: (d) => {
                    return moment(d.registerat).format("DD-MM-YYYY");
                },
            },
            {
                Header: "Actions",
                // accessor: "id",
                accessor: ({ rfid }) => {
                    // console.log(rfid)
                    return (
                        <div>
                            <span>
                                <a
                                    href={`/dashboard/rfid_report/viewReportSingleRfid/${rfid}`}
                                    rel="tooltip"
                                    title="View"
                                >
                                    <FaEye />
                                </a>
                            </span>
                            &nbsp;&nbsp;
                        </div>
                    );
                },
            },

        ],
        []
    );

    return (
        <Container fluid className="">
            <PureBreadcrumbs />
            <br />
            <div className="pt-5">
                <Row className="">
                    <Col>
                        <Card className="customercard">
                            <Card.Header>
                                <Row className="align-items-center">
                                    <Col xs={6} md={6} className="rfid-test">
                                        {t("RFID Report")}
                                    </Col>

                                    <Col xs={6} md={6} className="text-right">
                                        <DownloadBindRfidDetails {...props} data={data} />
                                    </Col>
                                </Row>
                            </Card.Header>
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "250px",
                            }}>{t("Loading data...")}</h3> :
                                props.fetchRfidListRes && props.fetchRfidListRes.data && props.fetchRfidListRes.data.length !== null ? (
                                    <div>
                                        <Table data={data} columns={columns} />
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <h3>{t("No Data Found")}</h3>
                                    </div>
                                )}
                        </Card>
                    </Col>
                </Row>

            </div>

        </Container >
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.customer.isLoading,
        fetchRfidListRes: state.customer.fetchRfidList,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getRfid: () => dispatch(fetchRfidList()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RfidReport);
