import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import ApexCharts from "react-apexcharts";
import { format } from "date-fns";
import PureBreadcrumbs from "../../breadcrums";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchSessionTypeByDate } from "../../../../store/actions/insightAction";
import { useTranslation } from "react-i18next";

function SessionTypeReport(props) {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 3600 * 1000 * 720)
  );
  const [endDate, setEndDate] = useState(
    new Date()
  );
  // console.log(startDate);
  useEffect(() => {
    props.getSTByDate(startDate.toISOString().slice(0, 10), endDate.toISOString().slice(0, 10));
  }, []);

  const onSubmit = () => {
    props.getSTByDate(startDate.toISOString().slice(0, 10), endDate.toISOString().slice(0, 10));
  };

  let oList = [],
    aList = [],
    rList = [];

  const lbs =
    props.total_s_t.List &&
    props.total_s_t.List.map((m) => {
      if (m.type == "App") {
        aList.push(m.total);
      }
      if (m.type == "RFID") {
        rList.push(m.total);
      }
      if (m.type == "") {
        oList.push(m.total);
      }
    });

  // console.log(aList[0]);
  // console.log(rList[0]);
  // console.log(oList[0]);

  const sessionType = {
    series: [
      {
        name: "Session Type",
        data: [aList[0], rList[0], oList[0]],
      },
    ],
    options: {
      xaxis: {
        categories: [format(startDate, "MMM")],
        labels: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        tooltip: {
          enabled: true,
          offsetY: 5,
        },
      },
      colors: ["#5559C2", "#0BCF6B", "rgba(173,181,189,102)"],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius:"5"
        },
      },
      dataLabels: {
        enabled: false,
      },
    },
  };
  return (
    <Container fluid className="pb-2">
      <PureBreadcrumbs />
      <br />
      <Row className="mt-4 mb-4">
        <Col>
          <Card className="customercard">
            <Card.Header>
              <Row>
                <Col xs={12} md={4}>
                  <p>{t("Session Type Reports")}</p>
                </Col>

                <Col xs={12} md={8}>
                  <div className="row justify-content-between float-right">
                    <div className="row justify-content-center">
                      <div className="col-auto">
                        <DatePicker
                          className="gray-border"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          maxDate={endDate}
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                      <span style={{ paddingTop: "7px" }}> To </span>
                      <div className="col-auto mx-2">
                        <DatePicker
                          className="gray-border"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          maxDate={new Date()}
                          todayButton="Today"
                          showDisabledMonthNavigation
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>

                    <div className="csvbutton ml-3 mt-0">
                      <button className="btn btn-sm" onClick={onSubmit}>
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#676767",
                            fontWeight: "600",
                          }}
                        >
                          GO
                        </span>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card>
              <ApexCharts
                options={sessionType.options}
                series={sessionType.series}
                type="bar"
                height={650}
              />
            </Card>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    total_s_t: state.insight.daterange_session_type,
    loading: state.insight.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSTByDate: (start, end) => dispatch(fetchSessionTypeByDate(start, end)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SessionTypeReport);
