import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useTranslation } from 'react-i18next';

const ChargerReportPdf = (props) => {
    const { t } = useTranslation();

    const generatePDF = () => {
        console.log("props", props);
        const unit = "pt";
        const size = "A4";
        const orientation = "landscape";
        const marginLeft = 40;
        const marginTop = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(12);

        // Define headers with formatted names
        const headers = [
            "Evse I'd", "Station Name", "Location", "City", "State", "Total Amount", "Total Energy",

        ];

        // Map data from props to match headers
        const data = props.data?.map(obj => {
            return [
                obj.evse_id,
                obj.stationid,
                obj.location,
                obj.city,
                obj.state,
                obj.total,
                obj.energy,
            ];
        });


        let content = {
            startY: 50,
            head: [headers],
            body: data
        };

        doc.text("Charger Report", marginLeft, 40);
        doc.autoTable(content);
        doc.save("Charger Report.pdf");
    };

    const handleButtonClick = () => {
        generatePDF();
    };

    return (
        <div>
            <button className='pdfbutton' onClick={handleButtonClick} disabled={props.data?.length === 0} type="button">{t("Generate Charger Report PDF")}</button>
        </div>
    );
};

export default ChargerReportPdf;
