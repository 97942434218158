import React, { useMemo, useEffect } from "react"
import { connect } from "react-redux";
import { FaEdit, FaEye } from "react-icons/fa";
import { activateAccount, allCutomersAction, deactivateAccount } from "../../../../store/actions/customerActions";
import Table from "../../reacttable/table";
import "../../reacttable/reactTable.css"
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";


function AccountList(props) {
    useEffect(() => {
        props.getCustomers()
    }, [])

    const activateUserAccount = (userid) => {
        // console.log(userid);
        props.activateAccounts(userid);

    }

    const deactivateUserAccount = (userid) => {
        // console.log(userid);
        props.deactivateAccounts(userid);
    };

    // console.log(props.activate.statuscode)

    useEffect(() => {
        if (props.activate.statuscode === 200) {
            props.getCustomers();
        }
    }, [props.activate])

    useEffect(() => {
        if (props.deactivate.statuscode === 200) {
            props.getCustomers();
        }
    }, [props.deactivate])

    const data = props.list && props.list.customers
    // console.log(data)

    const columns = useMemo(
        () => [
            {
                Header: 'First Name',
                accessor: 'firstname'
            },
            {
                Header: 'Last Name',
                accessor: 'lastname'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Mobile',
                accessor: 'mobile'
            },
            {
                Header: 'Country',
                accessor: 'country'
            },
            {
                Header: 'City',
                accessor: 'city'
            },
            {
                Header: 'State',
                accessor: 'state'
            },
            {
                Header: 'Account Type',
                accessor: 'accounttype'
            },
            {
                Header: 'Marketing Consent',
                id: "communication",
                accessor: d => {
                    return d.communication ? <span className="text-success">Given</span> : <span className="text-danger">Not Given</span>
                }
            },
            {
                Header: 'Create Date',
                accessor: 'createat'
            },
            {
                Header: 'Status',
                id: "status",
                accessor: d => {
                    return d.status ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>
                }
            },
            {
                Header: 'Actions',
                accessor: 'userid',
                Cell: (userid) => {
                    return (
                        <div>
                            <span>
                                <a href={`/dashboard/accountcard/viewaccount/${userid.value}`}
                                    rel="tooltip"
                                    title="View"
                                >
                                    <FaEye />
                                </a></span>&nbsp;&nbsp;
                            <span>
                                <a href={`/dashboard/accountcard/updateaccount/${userid.value}`}
                                    rel="tooltip"
                                    title="Edit"
                                >
                                    <FaEdit />
                                </a>
                            </span>
                            &nbsp;&nbsp;
                            <span>
                                <TiTick
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => activateUserAccount(userid.value)}
                                    title="Activate"
                                />
                            </span>
                            &nbsp;&nbsp;
                            <span>
                                <AiOutlineCloseCircle
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => deactivateUserAccount(userid.value)}
                                    title="De-Activate"
                                />
                            </span>
                        </div>

                    );
                },
            }
        ],
        []
    );


    return (

        <div>
            {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "250px",
            }}>Loading data...</h3> :
                data && data.length > 0 ?
                    (<div> <Table data={data} columns={columns}></Table></div>)
                    :
                    (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><h3 className="loader">No data found</h3></div>)
            }
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        list: state.customer.fetchcutomers,
        activate: state.customer.activateAccount,
        deactivate: state.customer.deactivateAccount,
        loading: state.customer.isLoading,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getCustomers: () => dispatch(allCutomersAction()),
        activateAccounts: (userid) => dispatch(activateAccount(userid)),
        deactivateAccounts: (userid) => dispatch(deactivateAccount(userid))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountList)