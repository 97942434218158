import React, { useState, useRef, useEffect } from "react";
import Switch from "react-bootstrap/esm/Switch";
import { Route, Redirect } from "react-router-dom";
import { logoutAction } from "../../store/actions/authAction";
import { connect, useDispatch } from "react-redux";
import { MdNotificationsActive } from 'react-icons/md';
import Insight from "./insights/insightCard";
import "./layout.css";
import "./dashboardNotification.css";
import AccountCard from "./customers/account/accountCard";
import WalletCard from "./customers/wallet/walletCard";
import RfidCard from "./customers/rfids/rfidCard";
import ViewEVSE from "./evse/viewevse/viewEvseCard";
import ViewRFID from "./evse/viewRfid/viewRfidCard";
import CreateNewRfid from "./evse/addRfid/createRfid";
import ViewOrganisation from "./organisation/viewOrganisation/organisationCard";
import CreateOrganisationTariff from "./organisation/addOrganisation/createOrganisation";
import ViewAccess from "./access/viewaccess/accessCard";
import ReportCard from "./report/reportCard";
import PlugUnplugReport from "./report/plug_unplug_report";
import CreateAccess from "./access/newaccess/createAccess";
import AdvertisementComponent from "./advertisement/create";
import Fleet from "./fleet/fleet";
import ViewCustmomerData from "./customers/account/viewSingleAccount";
import SingleWallet from "./customers/wallet/viewSingleWallet";
import ViewSingleOrganisation from "./organisation/viewOrganisation/singleOrganisationView";
import UserDetails from "./access/viewaccess/viewSingleUser";
import BootNotification from "./notification/bootstatus/bootstatus";
import OCPPError from "./notification/error/error";
import FetchAdvertise from "./advertisement/view/view";
import CapmaignCard from "./campaign/newCampaign/campaignCard";
import ViewCampaign from "./campaign/viewCampaign/campaignList";
import UpdateWallet from "./customers/wallet/updateWallet";
import UpdateCustmomerData from "./customers/account/updateAccount";
import UpdateAccess from "./access/viewaccess/updateAccess";
import InsightChargerStatus from "./evse/status/chargerStatus";
import UserSettings from "./settings/settings";
import CommandsCard from "./evse/commands/commandsCard";
import UpdateOrganisation from "./organisation/viewOrganisation/updateOrg";
import CreatePromotion from "./organisation/promotion/newPromotion";
import ViewPromotion from "./organisation/promotion/viewPromotion";
import ViewCorporateCode from "./organisation/corporateCode/viewCode";
import CreateCorporateCode from "./organisation/corporateCode/newcode";
import CityComparisionGraph from "./insights/cityGraph/cityComparisionGraph";
import StationComparisionGraph from "./insights/stationGraph/stationComparisionGraph";
import LiveCharging from "./livecharging/livecharging";
import EnergyRevenueReport from "./insights/energyrevenue/energyrevenueReport";
import SaveFleetVehicle from "./fleet/fleet_onboarding/savevehicle";
import FleetReport from "./fleet/report";
import EVSEUpdateMasterComponent from "./evse/viewevse/evseUpdateMaster";
import LocationConusmeCard from "./ocpi/ocpi_emsp/ocpi_consume_location/consumeLocatioCard";
import AddOCPIPartner from "./ocpi/ocpi_emsp/ocpi_emsp_partner/addPartner";
import ViewOCPIPartner from "./ocpi/ocpi_emsp/ocpi_emsp_partner/viewPartner";
import ViewSinglePartner from "./ocpi/ocpi_emsp/ocpi_emsp_partner/viewSinglePartner";
import UpdateRoamingPartner from "./ocpi/ocpi_emsp/ocpi_emsp_partner/updatePartner";
import SessionTypeReport from "./insights/sessiontypegraph/sessionTypeReport";
import SessionStatusReport from "./insights/sessionsgraph/sessionGraphReport";
import HeavyVehicles from "./fleet/heavyVehicles";
import LightVehicles from "./fleet/lightVehicles";
import FleetLiveCharging from "./fleet/fleetLiveCharging";
import LoadManagement from "./loadmanagement/loadCard";
import OCPPLogComponent from "./log/logCard";
import CustomerPridictiveInsight from "./insights/customerPredictive/customerPredictiveCard";
import CustomerPredictiveList from "./insights/customerPredictive/customerPredictiveList";
import AddBooking from "./bookingManagement/addBookingCard";
import ManageChargerBooking from "./bookingManagement/manageBooking";
import CustomerBookingCard from "./bookingManagement/customerBooking/customerBookingCard";
import UpdateChargerSlots from "./bookingManagement/customerBooking/chargerSlots/updateChargerSlots";
import FleetOnBoardingCard from "./fleet/fleet_onboarding/onboardcard";
import RemoteManagementComponent from "../dashboard/remotemanagement/remote_card"
import AddChargingStationComponent from "./evse/addEVSE/add_station";
import CreateStationGroup from "./evse/station_Group/stationGroup";
import StationGroupList from "./evse/station_Group/viewStationGroup";
import TimeOfUseTariffListComponent from "../dashboard/tariff/viewtariff/view_timeofuse_tariff"
import ViewSingleTimeOfUse from "../dashboard/tariff/viewtariff/single_time_of_use"
import UpdateTimeOfUseTariff from "./tariff/viewtariff/update_time_of_use_tariff";
import RevenueInsightCard from "./insights/revenueInsight/revenueInsightCard";
import EnergyInsightCard from "./insights/energyInsight/energyInsightCard";
import ChargerSlotsList from "./bookingManagement/customerBooking/chargerSlots/chargerSlotsList";
import ViewSingleStationV2Component from "./evse/viewevse/view_single_station";
import CreateTicket from "./ticketManagement/createTicket";
import TicketView from "./ticketManagement/ticketView";
import ViewSingleTicket from "./ticketManagement/singleViewTicket";
import UpdateTicket from "./ticketManagement/updateTicket";
import ChargerReport from "./report/chargerReport";
import RevenueSharingList from "./report/revenueSharingList";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import MainSideToolBar from "./sidebar/maintoolbar";
import RoamingReport from "./ocpi/ocpi_emsp/externalTransactionReport";
import RoamingChargerReport from "./ocpi/roamingChargerReport";
import CustomOffCanvas from "./DashboardNotification";
import ViewSingleAdminStatus from "./customers/account/viewSingleAdminStatus";
import { fetchNotificationDataAPI } from "../../store/actions/insightAction";
import StaicTariff from "./tariff/newtariff/static_tariff";
import DynamicTariff from "./tariff/newtariff/dynamic_tariff";
import ViewTimeSlot from "./tariff/viewtariff/view_time_slot";
import ViewSingleTimeSlot from "./tariff/viewtariff/view_single_time_slot";
import UpdateDynamicTariff from "./tariff/viewtariff/update_time_slot";
import UpdatePromotion from "./organisation/promotion/updatePromotion";
import UpdateCorporateCode from "./organisation/corporateCode/updateCorporateCode";
import UpdateRFID from "./evse/viewRfid/updaterfid";
import LanguageSwitcher from "../LanguageSwitcher";
import { useTranslation } from 'react-i18next';
import ViewChargerSlot from "./bookingManagement/customerBooking/chargerSlots/viewChargerSlot";
import ViewSingleAdvertise from "./advertisement/view/viewSingleAdvertise";
import UpdateAdvertise from "./advertisement/view/updateAdvertise";
import ViewSingleSMSCampaign from "./campaign/viewCampaign/viewSMS/viewSingleSms";
import UpdateSMSCampaign from "./campaign/viewCampaign/viewSMS/updateSms";
import ViewSingleNotification from "./campaign/viewCampaign/viewNotification/viewSingleNotification";
import UpdateNotification from "./campaign/viewCampaign/viewNotification/updateNotification";
import ViewSingleEmail from "./campaign/viewCampaign/viewEmail/viewSingleEmail"
import UpdateEmail from "./campaign/viewCampaign/viewEmail/updateEmail"
import CustomerGrouping from "./customers/customerGrouping/customerGrouping";
import ViewCustomerGroup from "./customers/customerGrouping/viewCustomerGroup";
import UpdateCustomerGrouping from "./customers/customerGrouping/updateCustomerGroup";
import ViewALlCustomerGroup from "./customers/customerGrouping/viewCustomerGroupCard";
import ViewSingleStationGroup from "./evse/station_Group/viewSingleStationGroup";
import UpdateStationGroup from "./evse/station_Group/updateStationGroup";
import CreateStationGroupWithTariff from "./evse/station_Group_With_Tariff/station_group_with_tariff";
import StationGroupWithTariffList from './evse/station_Group_With_Tariff/view_station_group_with_tariff'
import ViewSingleStationGroupWithTariff from './evse/station_Group_With_Tariff/single_station_group_with_tariff'
import UpdateStationGroupWithTariff from "./evse/station_Group_With_Tariff/updateStationGroupWithTariff";
import AdvertisementReport from "./advertisement/view/advertisementReport";
import AdvertiseAllReport from "./advertisement/view/fetchAllReport";
import TransactionList from "./financeManagement/transactionList";
import SinglePayout from "./financeManagement/singlePayout";
import AddBankDetails from "./financeManagement/addBank";
import ViewBankList from "./financeManagement/viewBank";
import FinanceReport from "./financeManagement/payout";
import SingleConnectBankInfo from "./financeManagement/singleBankInfo";
import CreateRule from "./loyaltyManagement/rule_manegement/createRule";
import ViewRuleList from "./loyaltyManagement/rule_manegement/viewRuleList";
import ViewSingleRule from "./loyaltyManagement/rule_manegement/viewSingleRule";
import UpdateRule from "./loyaltyManagement/rule_manegement/updateRule";
import CreateProgram from "./loyaltyManagement/program_management/createProgram";
import ViewProgramList from "./loyaltyManagement/program_management/viewProgramList";
import ViewSingleProgram from "./loyaltyManagement/program_management/singleViewProgram";
import CreateRedeem from "./loyaltyManagement/redeem/createRedeem";
import ViewRedeemList from "./loyaltyManagement/redeem/viewRedeemList";
import UpdateProgram from "./loyaltyManagement/program_management/updateProgram";
import ViewSingleRedeemRule from "./loyaltyManagement/redeem/viewSingleRedeemRule";
import UpdateRedeem from "./loyaltyManagement/redeem/updateRedeem";
import CustomerRedeemption from "./loyaltyManagement/customerRedeemption/customerRedeemption";
import ViewSingleCustomerGraph from "./loyaltyManagement/customerRedeemption/singleCustomerRedemptionGraph";
import CreateStateGroup from "./access/newaccess/createStateGroup";
import ViewAllStateGroup from "./access/viewStateGroup/viewStateGroup";
import UpdateStateGroup from "./access/viewStateGroup/updateStateGroup";
import CreateSubscription from "./customers/subscription/createSubscription";
import ViewSubscription from "./customers/subscription/viewSubscription";
import ViewSingleSubscription from "./customers/subscription/viewSingleSubscription";
import UpdateSubscription from "./customers/subscription/updateSubscription";
import ViewDiscoveryEVSE from "./evse/viewevse/viewDiscoveryCard";
import GuestSignUp from "./customers/account/guestSignUp";
import ViewSingleOcpiList from "./ocpi/ocpi_emsp/ocpi_consume_location/viewSingleOcpiList";
import ViewPartnerTariffList from "./ocpi/ocpi_emsp/ocpi_consume_tariff/viewPartnerTariffList";
import ViewSingleRfid from "./evse/viewRfid/viewSingleRfid";
import CreateManualReconciliation from "./financeManagement/createManualReconcilation"
import ViewManualReconcilation from "./financeManagement/viewManualReconcilation";
import ViewSingleManualReconcilation from "./financeManagement/viewSingleManualReconcilation";
import UpdateManualReconciliation from "./financeManagement/updateManualReconciliation";
import ConsumedTariffView from "./ocpi/ocpi_emsp/ocpi_consume_tariff/viewSingleConsumeTariff";
import GenerateOCPIToken from "./ocpi/ocpi_cpo/generateToken";
import SingleTransactionView from "./report/singleTransaction";
import OcpiStationCard from "./ocpi/ocpi_cpo/ocpi_station/ocpiStationCard";
import ViewSingleOcpiStation from "./ocpi/ocpi_cpo/ocpi_station/viewSingleOcpiStation";
import FailedTransactionReport from "./report/failedTransactionReport";
import SuccessTransactionReport from "./report/successTransactionReport";
import InternalTransactionReport from "./ocpi/ocpi_cpo/ocpi_station/internalTransactionReport";
import ViewEmspPartner from "./ocpi/ocpi_cpo/viewEmspPartner";
import ViewSingleEmspPartner from "./ocpi/ocpi_cpo/viewSingleEmspPartner";
import OcpiLocationStatus from "./ocpi/ocpi_emsp/ocpi_consume_location/ocpiLocationStatus";
import RfidReport from "./report/RFIDReport/rfidReport";
import ViewReportSingleRfid from "./report/RFIDReport/viewSingleRfid";
import Co2ReductionList from "./insights/co2ReductionList";
import RFIDLogs from "./log/rfidLogs";

function DashboardLayout(props) {
  // console.log("props", props)
  const { history } = props;
  // console.log("history", history)

  const { access, name } = JSON.parse(localStorage.getItem("user"));
  // console.log("access: " , access)
  const [sideDrawOpen, setIsOpen] = useState(true);
  const [removeEvent, setRemoveEvent] = useState(null);
  const [sideMobileDrawOpen, setMobileIsOpen] = useState(true);
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);

  const mobilewidth = windowWidth.current < 590;

  const mobileheight = windowHeight.current < 1000;
  const dispatch = useDispatch();

  useEffect(() => {
    props.getNotificationAPI();
  }, []);

  const notiflist = props.fetchNotifi && props.fetchNotifi.data;
  const notifyLength = notiflist && notiflist.filter((item) => item.isRead === false);


  function drawToggleButtonHandler() {
    if (mobilewidth === true && mobileheight === true) {
      setMobileIsOpen(!sideMobileDrawOpen);
    }
    setIsOpen(!sideDrawOpen);
  }

  function LogoutHandler() {
    dispatch(logoutAction());
    history.push("/");
  }

  const handleShowOffCanvas = () => {
    setShowOffCanvas(true);
    // console.log("show notifiation", true);
  };

  const handleCloseOffCanvas = () => {
    setShowOffCanvas(false);
    // console.log("close notifiation", false);
  };

  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div
      className={
        "wrapper" +
        ((mobilewidth === true ? !sideMobileDrawOpen : sideDrawOpen)
          ? " toggled"
          : "")
      }
    >
      <header className="toolbar">
        <nav className="toolbar_navigation">
          {/* <div>
            <button className="toggle-button" onClick={drawToggleButtonHandler}>
              <img
                src={process.env.PUBLIC_URL + "/images/layout/hammenu.svg"}
                alt=""
              />
            </button>
          </div> */}
          <div className="toolbar_logo">
            <Link to="/dashboard">
              <img
                src={process.env.PUBLIC_URL + "/images/c_logo.png"}
                style={{ width: "110px", marginTop: "12px" }}
                alt="logo"
                className="companylogo"
              />
            </Link>
          </div>
          <div className="spacer">
          </div>
          <div className="toolbar_navigation_items">
            <ul className="navbar-right unordered-list">
              <li className="mt-1">
                <LanguageSwitcher {...props} onChangeLanguage={changeLanguage} />
              </li>
              {/* <li className="mr-3">

                <div className="notification-icon" onClick={handleShowOffCanvas}>
                  <MdNotificationsActive size={32} color="gray" />
                  {notifyLength && notifyLength.length > 0 && (
                    <span className="notification-badge" style={{ userSelect: "none" }}>
                      {notifyLength && notifyLength.length >= 10 ? "+9" : notifyLength.length}
                    </span>
                  )}
                </div>
                {showOffCanvas && <CustomOffCanvas {...props} handleClose={handleCloseOffCanvas} />}
              </li> */}

              <li className="list-item">
                <Link to="/dashboard/settings" className="img" >
                  <img style={{ userSelect: "none" }}
                    src={process.env.PUBLIC_URL + "/images/layout/settings.svg"}
                    alt="Settings"
                    title="Settings"
                  />
                </Link>
              </li>
              {/* <li className="list-item user_name">{name}</li> */}
              <li className="list-item">
                {" "}
                <img
                  src={process.env.PUBLIC_URL + "/images/layout/LogoutIcon.svg"}
                  alt="Sign Out"
                  title="Sign Out"
                  onClick={LogoutHandler}
                />
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <div className="sidebar_wrapper-1">
        <MainSideToolBar match={props.match}></MainSideToolBar>
      </div>

      <div className={`main_container  container-margin-0`}>
        <Switch>
          <Route exact path={`${props.match.path}`}>
            <Redirect to={`${props.match.path}/${access?.[0]?.menu?.[0]?.key}`} />
          </Route>
          <Route exact path={`${props.match.path}/insight`}>
            <Insight></Insight>
          </Route>
          <Route exact path={`${props.match.path}/customer_insight`}>
            <CustomerPridictiveInsight></CustomerPridictiveInsight>
          </Route>
          <Route exact path={`${props.match.path}/livecharging`}>
            <LiveCharging></LiveCharging>
          </Route>
          <Route exact path={`${props.match.path}/accountcard`}>
            <AccountCard />
          </Route>
          <Route exact path={`${props.match.path}/walletcard`}>
            <WalletCard />
          </Route>
          <Route exact path={`${props.match.path}/rfid`}>
            <RfidCard />
          </Route>
          {/* <Route exact path={`${props.match.path}/rfid`}>
            <SinglePayout />
          </Route> */}
          <Route exact path={`${props.match.path}/new_tariff`}>
            {/* <CreatePriceComponent /> */}
            <StaicTariff></StaicTariff>
          </Route>

          <Route exact path={`${props.match.path}/view_tariff`}>
            <TimeOfUseTariffListComponent></TimeOfUseTariffListComponent>
          </Route>

          <Route exact path={`${props.match.path}/new_time_slot`}>
            <DynamicTariff></DynamicTariff>
          </Route>

          <Route exact path={`${props.match.path}/view_time_slot`}>
            <ViewTimeSlot></ViewTimeSlot>
          </Route>
          <Route exact path={`${props.match.path}/view_time_slot/view_single_time_slot/:id`}>
            <ViewSingleTimeSlot></ViewSingleTimeSlot>
          </Route>
          <Route exact path={`${props.match.path}/update_time_slot/:id`}>
            <UpdateDynamicTariff></UpdateDynamicTariff>
          </Route>

          <Route exact path={`${props.match.path}/add_evse`}>
            <AddChargingStationComponent></AddChargingStationComponent>
          </Route>
          <Route exact path={`${props.match.path}/new_evse_tariff_group`}>
            <CreateStationGroupWithTariff />
          </Route>
          <Route exact path={`${props.match.path}/view_evse_tariff_group`}>
            <StationGroupWithTariffList />
          </Route>
          <Route exact path={`${props.match.path}/view_evse_tariff_group/viewSingleStationGroupWithTariff/:id`}>
            <ViewSingleStationGroupWithTariff />
          </Route>
          <Route exact path={`${props.match.path}/view_evse_tariff_group/updateSingleStationGroupWithTariff/:id`}>
            <UpdateStationGroupWithTariff />
          </Route>
          <Route exact path={`${props.match.path}/new_evse_customer_group`}>
            <CreateStationGroup />
          </Route>
          <Route exact path={`${props.match.path}/view_evse_customer_group`}>
            <StationGroupList />
          </Route>
          <Route exact path={`${props.match.path}/view_evse_customer_group/viewSingleStationGroup/:id`}>
            <ViewSingleStationGroup />
          </Route>
          <Route exact path={`${props.match.path}/view_evse_customer_group/updateSingleStationGroup/:id`}>
            <UpdateStationGroup />
          </Route>
          <Route exact path={`${props.match.path}/view_evse`}>
            <ViewEVSE />
          </Route>

          <Route exact path={`${props.match.path}/viewsingleevse/:stationid`}>
            {/* <ViewSingleEVSE /> */}
            <ViewSingleStationV2Component></ViewSingleStationV2Component>
          </Route>

          <Route exact path={`${props.match.path}/remote`}>
            <RemoteManagementComponent></RemoteManagementComponent>
          </Route>
          <Route exact path={`${props.match.path}/ocpi_consume_location`}>
            <LocationConusmeCard />
          </Route>
          <Route exact path={`${props.match.path}/view_rfid_pin`}>
            <ViewRFID />
          </Route>
          <Route exact path={`${props.match.path}/updaterfid/:id`}>
            <UpdateRFID />
          </Route>
          <Route exact path={`${props.match.path}/add_rfid_pin`}>
            <CreateNewRfid />
          </Route>
          <Route exact path={`${props.match.path}/view_organisation`}>
            <ViewOrganisation />
          </Route>
          <Route exact path={`${props.match.path}/add_organisation`}>
            <CreateOrganisationTariff />
          </Route>
          <Route exact path={`${props.match.path}/view_access`}>
            <ViewAccess />
          </Route>
          <Route exact path={`${props.match.path}/report`}>
            <ReportCard />
          </Route>
          <Route exact path={`${props.match.path}/plug_unplug_report`}>
            <PlugUnplugReport />
          </Route>
          <Route exact path={`${props.match.path}/add_access`}>
            <CreateAccess />
          </Route>
          <Route exact path={`${props.match.path}/adv_report`}>
            <AdvertisementReport />
          </Route>
          <Route exact path={`${props.match.path}/adv_report_all`}>
            <AdvertiseAllReport />
          </Route>
          <Route exact path={`${props.match.path}/new_advertisement`}>
            <AdvertisementComponent />
          </Route>
          <Route exact path={`${props.match.path}/viewSingleAdvertise/:id`}>
            <ViewSingleAdvertise />
          </Route>
          <Route exact path={`${props.match.path}/updateAdvertise/:id`}>
            <UpdateAdvertise />
          </Route>
          <Route exact path={`${props.match.path}/view_fleet`}>
            <Fleet />
          </Route>
          <Route exact path={`${props.match.path}/customerGrouping`}>
            <CustomerGrouping />
          </Route>
          <Route exact path={`${props.match.path}/new_customer_group`}>
            <CustomerGrouping />
          </Route>
          <Route exact path={`${props.match.path}/view_customer_group`}>
            <ViewALlCustomerGroup />
          </Route>
          <Route exact path={`${props.match.path}/accountcard/viewCustomerGroup/:id`}>
            <ViewCustomerGroup />
          </Route>
          <Route exact path={`${props.match.path}/accountcard/updateCustomerGroup/:id`}>
            <UpdateCustomerGrouping />
          </Route>
          <Route exact path={`${props.match.path}/accountcard/viewaccount/:userid`}>
            <ViewCustmomerData />
          </Route>
          <Route exact path={`${props.match.path}/accountcard/adminstatus/:id`}>
            <ViewSingleAdminStatus />
          </Route>
          <Route exact path={`${props.match.path}/accountcard/updateaccount/:userid`}>
            <UpdateCustmomerData />
          </Route>
          <Route exact path={`${props.match.path}/walletcard/viewwallet/:userid`}>
            <SingleWallet />
          </Route>
          <Route exact path={`${props.match.path}/view_tariff/singletariff/:id`}>
            <ViewSingleTimeOfUse></ViewSingleTimeOfUse>
          </Route>
          <Route exact path={`${props.match.path}/view_organisation/single/:id`}>
            <ViewSingleOrganisation />
          </Route>
          <Route exact path={`${props.match.path}/view_access/single/:userid`}>
            <UserDetails />
          </Route>
          <Route exact path={`${props.match.path}/boot_notification`}>
            <BootNotification />
          </Route>
          <Route exact path={`${props.match.path}/errors`}>
            <OCPPError />
          </Route>
          <Route exact path={`${props.match.path}/rfid_log`}>
            <RFIDLogs />
          </Route>
          <Route exact path={`${props.match.path}/view_advertisement`}>
            <FetchAdvertise />
          </Route>
          <Route exact path={`${props.match.path}/viewSingleEmail/:id`}>
            <ViewSingleEmail />
          </Route>
          <Route exact path={`${props.match.path}/updateEmail/:id`}>
            <UpdateEmail />
          </Route>
          <Route exact path={`${props.match.path}/add_campaign`}>
            <CapmaignCard />
          </Route>
          <Route exact path={`${props.match.path}/viewSingleSms/:id`}>
            <ViewSingleSMSCampaign />
          </Route>
          <Route exact path={`${props.match.path}/updateSms/:id`}>
            <UpdateSMSCampaign />
          </Route>
          <Route exact path={`${props.match.path}/view_campaign`}>
            <ViewCampaign />
          </Route>
          <Route exact path={`${props.match.path}/viewSingleNotification/:id`}>
            <ViewSingleNotification />
          </Route>
          <Route exact path={`${props.match.path}/updateNotification/:id`}>
            <UpdateNotification />
          </Route>
          <Route exact path={`${props.match.path}/walletcard/updatewallet/:userid`}>
            <UpdateWallet />
          </Route>
          <Route exact path={`${props.match.path}/updatetariff/:id`}>
            {/* <UpdateTarfiff /> */}
            <UpdateTimeOfUseTariff></UpdateTimeOfUseTariff>
          </Route>
          <Route exact path={`${props.match.path}/updateevse/:stationid`}>
            {/* <UpdateEVSE /> */}
            <EVSEUpdateMasterComponent></EVSEUpdateMasterComponent>
          </Route>
          <Route exact path={`${props.match.path}/edit_access/:userid`}>
            <UpdateAccess />
          </Route>
          <Route exact path={`${props.match.path}/insight/evse/chargerstats/:status`}>
            <InsightChargerStatus />
          </Route>
          <Route exact path={`${props.match.path}/settings`}>
            <UserSettings />
          </Route>
          <Route exact path={`${props.match.path}/charger/commands/:stationid`}>
            <CommandsCard />
          </Route>
          <Route exact path={`${props.match.path}/updateorganisation/:id`}>
            <UpdateOrganisation />
          </Route>
          <Route exact path={`${props.match.path}/add_promotion`}>
            <CreatePromotion />
          </Route>
          <Route exact path={`${props.match.path}/view_promotion`}>
            <ViewPromotion />
          </Route>
          <Route exact path={`${props.match.path}/updatepromotion/:id`}>
            <UpdatePromotion />
          </Route>
          <Route exact path={`${props.match.path}/view_corporate_code`}>
            <ViewCorporateCode />
          </Route>
          <Route exact path={`${props.match.path}/add_corporate_code`}>
            <CreateCorporateCode />
          </Route>
          <Route exact path={`${props.match.path}/UpdateCorporateCode/:id`}>
            <UpdateCorporateCode />
          </Route>
          <Route exact path={`${props.match.path}/citycomparision`}>
            <CityComparisionGraph />
          </Route>
          <Route exact path={`${props.match.path}/stationcomparision`}>
            <StationComparisionGraph />
          </Route>
          <Route exact path={`${props.match.path}/energyrevenue`}>
            <EnergyRevenueReport />
          </Route>
          <Route exact path={`${props.match.path}/save_vehicle`}>
            <SaveFleetVehicle />
          </Route>
          <Route exact path={`${props.match.path}/fleet_report`}>
            <FleetReport />
          </Route>
          <Route exact path={`${props.match.path}/add_partner`}>
            <AddOCPIPartner />
          </Route>
          <Route exact path={`${props.match.path}/view_partner`}>
            <ViewOCPIPartner />
          </Route>
          <Route exact path={`${props.match.path}/view_partner/singlepartner/:party_id`}>
            <ViewSinglePartner />
          </Route>
          <Route exact path={`${props.match.path}/view_partner/edit_partner/:id`}>
            <UpdateRoamingPartner />
          </Route>
          <Route exact path={`${props.match.path}/session_type_report`}>
            <SessionTypeReport />
          </Route>
          <Route exact path={`${props.match.path}/session_status_report`}>
            <SessionStatusReport />
          </Route>
          <Route exact path={`${props.match.path}/view_fleet/heavy_vehicles`}>
            <HeavyVehicles />
          </Route>
          <Route exact path={`${props.match.path}/view_fleet/light_vehicles`}>
            <LightVehicles />
          </Route>
          <Route exact path={`${props.match.path}/view_fleet/fleet_live_charging`}>
            <FleetLiveCharging />
          </Route>
          <Route exact path={`${props.match.path}/loadmanagement`}>
            <LoadManagement />
          </Route>

          <Route exact path={`${props.match.path}/log`}>
            <OCPPLogComponent></OCPPLogComponent>
          </Route>

          <Route exact path={`${props.match.path}/customerpredictionlist`}>
            <CustomerPredictiveList></CustomerPredictiveList>
          </Route>
          <Route exact path={`${props.match.path}/add_booking`}>
            <AddBooking></AddBooking>
          </Route>
          <Route exact path={`${props.match.path}/managebooking/:stationid`}>
            <ManageChargerBooking></ManageChargerBooking>
          </Route>
          <Route exact path={`${props.match.path}/view_booking/`}>
            <CustomerBookingCard></CustomerBookingCard>
          </Route>
          <Route exact path={`${props.match.path}/viewchargerslots/:evse_id`}>
            <ViewChargerSlot></ViewChargerSlot>
          </Route>
          <Route exact path={`${props.match.path}/updateChargerSlots/:evse_id`}>
            <UpdateChargerSlots></UpdateChargerSlots>
          </Route>
          <Route exact path={`${props.match.path}/fleet_onboarding`}>
            <FleetOnBoardingCard></FleetOnBoardingCard>
          </Route>
          <Route exact path={`${props.match.path}/revenue_insight`}>
            <RevenueInsightCard></RevenueInsightCard>
          </Route>
          <Route exact path={`${props.match.path}/energy_insight`}>
            <EnergyInsightCard></EnergyInsightCard>
          </Route>
          <Route exact path={`${props.match.path}/charger_slots`}>
            <ChargerSlotsList></ChargerSlotsList>
          </Route>
          <Route exact path={`${props.match.path}/new_ticket`}>
            <CreateTicket></CreateTicket>
          </Route>
          <Route exact path={`${props.match.path}/view_ticket`}>
            <TicketView></TicketView>
          </Route>
          <Route exact path={`${props.match.path}/singleViewTicket/:ticket_id`}>
            <ViewSingleTicket></ViewSingleTicket>
          </Route>
          <Route exact path={`${props.match.path}/updateTicket/:ticket_id`}>
            <UpdateTicket></UpdateTicket>
          </Route>
          <Route exact path={`${props.match.path}/charger_report`}>
            <ChargerReport></ChargerReport>
          </Route>
          <Route exact path={`${props.match.path}/revenue_sharing`}>
            <RevenueSharingList></RevenueSharingList>
          </Route>
          <Route exact path={`${props.match.path}/ocpi_report`}>
            <RoamingReport></RoamingReport>
          </Route>
          <Route exact path={`${props.match.path}/ocpi_charger_report`}>
            <RoamingChargerReport></RoamingChargerReport>
          </Route>
          <Route exact path={`${props.match.path}/add_bank_details`}>
            <AddBankDetails></AddBankDetails>
          </Route>
          <Route exact path={`${props.match.path}/view_back_details`}>
            <ViewBankList></ViewBankList>
          </Route>
          <Route exact path={`${props.match.path}/payout`}>
            <FinanceReport></FinanceReport>
          </Route>
          <Route exact path={`${props.match.path}/view_back_details/viewaccountifo/:id`}>
            <SingleConnectBankInfo></SingleConnectBankInfo>
          </Route>
          <Route exact path={`${props.match.path}/transaction_deatils`}>
            <TransactionList></TransactionList>
          </Route>
          <Route exact path={`${props.match.path}/release/:location/:operator`}>
            <SinglePayout></SinglePayout>
          </Route>
          <Route exact path={`${props.match.path}/create_rule`}>
            <CreateRule></CreateRule>
          </Route>
          <Route exact path={`${props.match.path}/view_rule`}>
            <ViewRuleList></ViewRuleList>
          </Route>
          <Route exact path={`${props.match.path}/view_rule/singleRule/:rule_name`}>
            <ViewSingleRule></ViewSingleRule>
          </Route>
          <Route exact path={`${props.match.path}/view_rule/updateRule/:rule_name`}>
            <UpdateRule></UpdateRule>
          </Route>
          <Route exact path={`${props.match.path}/create_program`}>
            <CreateProgram></CreateProgram>
          </Route>
          <Route exact path={`${props.match.path}/view_program`}>
            <ViewProgramList></ViewProgramList>
          </Route>
          <Route exact path={`${props.match.path}/view_program/singleProgram/:program_name`}>
            <ViewSingleProgram></ViewSingleProgram>
          </Route>
          <Route exact path={`${props.match.path}/updateProgram/:program_name`}>
            <UpdateProgram></UpdateProgram>
          </Route>
          <Route exact path={`${props.match.path}/create_redeem`}>
            <CreateRedeem></CreateRedeem>
          </Route>
          <Route exact path={`${props.match.path}/view_redeem`}>
            <ViewRedeemList></ViewRedeemList>
          </Route>
          <Route exact path={`${props.match.path}/view_redeem/singleRedeemRule/:redeem_name`}>
            <ViewSingleRedeemRule></ViewSingleRedeemRule>
          </Route>
          <Route exact path={`${props.match.path}/updateRedeemRule/:redeem_name`}>
            <UpdateRedeem></UpdateRedeem>
          </Route>
          <Route exact path={`${props.match.path}/customer_redemption_list`}>
            <CustomerRedeemption></CustomerRedeemption>
          </Route>
          <Route exact path={`${props.match.path}/viewSingleCustomerGraph/:userid`}>
            <ViewSingleCustomerGraph></ViewSingleCustomerGraph>
          </Route>
          <Route exact path={`${props.match.path}/create_state_group`}>
            <CreateStateGroup></CreateStateGroup>
          </Route>
          <Route exact path={`${props.match.path}/view_state_group`}>
            <ViewAllStateGroup></ViewAllStateGroup>
          </Route>
          <Route exact path={`${props.match.path}/view_state_group/updateStateGroup`}>
            <UpdateStateGroup></UpdateStateGroup>
          </Route>
          <Route exact path={`${props.match.path}/new_subscription`}>
            <CreateSubscription></CreateSubscription>
          </Route>
          <Route exact path={`${props.match.path}/view_subscription`}>
            <ViewSubscription></ViewSubscription>
          </Route>
          <Route exact path={`${props.match.path}/view_subscription/viewSingleSubscription/:name`}>
            <ViewSingleSubscription></ViewSingleSubscription>
          </Route>
          <Route exact path={`${props.match.path}/view_subscription/updateSubscription/:name`}>
            <UpdateSubscription></UpdateSubscription>
          </Route>
          <Route exact path={`${props.match.path}/view_discovery_chargers`}>
            <ViewDiscoveryEVSE></ViewDiscoveryEVSE>
          </Route>
          <Route exact path={`${props.match.path}/accountcard/guestSignup`}>
            <GuestSignUp></GuestSignUp>
          </Route>
          <Route exact path={`${props.match.path}/viewOcpiLocation/:name/:party_id`}>
            <ViewSingleOcpiList></ViewSingleOcpiList>
          </Route>
          <Route exact path={`${props.match.path}/view_partner_tariff/`}>
            <ViewPartnerTariffList></ViewPartnerTariffList>
          </Route>
          <Route exact path={`${props.match.path}/viewSingleRfid/:id`}>
            <ViewSingleRfid></ViewSingleRfid>
          </Route>
          <Route exact path={`${props.match.path}/create_manual_reconcilation`}>
            <CreateManualReconciliation></CreateManualReconciliation>
          </Route>
          <Route exact path={`${props.match.path}/view_manual_reconcilation`}>
            <ViewManualReconcilation></ViewManualReconcilation>
          </Route>
          <Route exact path={`${props.match.path}/viewSingleReconcilation/:id`}>
            <ViewSingleManualReconcilation></ViewSingleManualReconcilation>
          </Route>
          <Route exact path={`${props.match.path}/updateSingleReconcilation/:id`}>
            <UpdateManualReconciliation></UpdateManualReconciliation>
          </Route>
          <Route exact path={`${props.match.path}/view_consumed_tariff/singleocpitariff/:id/:party_id`}>
            <ConsumedTariffView></ConsumedTariffView>
          </Route>
          <Route exact path={`${props.match.path}/new_token`}>
            <GenerateOCPIToken></GenerateOCPIToken>
          </Route>
          <Route exact path={`${props.match.path}/report/single/:charging_id/:tag_id`}>
            <SingleTransactionView></SingleTransactionView>
          </Route>
          <Route exact path={`${props.match.path}/ocpi_cpo_stations`}>
            <OcpiStationCard></OcpiStationCard>
          </Route>
          <Route exact path={`${props.match.path}/viewOcpiCpoStation/:name`}>
            <ViewSingleOcpiStation></ViewSingleOcpiStation>
          </Route>
          <Route exact path={`${props.match.path}/failed_report`}>
            <FailedTransactionReport></FailedTransactionReport>
          </Route>
          <Route exact path={`${props.match.path}/success_report`}>
            <SuccessTransactionReport></SuccessTransactionReport>
          </Route>
          <Route exact path={`${props.match.path}/ocpi_cpo_report`}>
            <InternalTransactionReport></InternalTransactionReport>
          </Route>
          <Route exact path={`${props.match.path}/view_emsp_partner`}>
            <ViewEmspPartner></ViewEmspPartner>
          </Route>
          <Route exact path={`${props.match.path}/view_emsp_partner/viewSingleEmspPartner/:party_id`}>
            <ViewSingleEmspPartner></ViewSingleEmspPartner>
          </Route>
          <Route exact path={`${props.match.path}/ocpi/ocpi_emsp/ocpi_consume_location/ocpiLocationStatus/:status`}>
            <OcpiLocationStatus></OcpiLocationStatus>
          </Route>
          <Route exact path={`${props.match.path}/rfid_report`}>
            <RfidReport></RfidReport>
          </Route>
          <Route exact path={`${props.match.path}/rfid_report/viewReportSingleRfid/:rfid`}>
            <ViewReportSingleRfid></ViewReportSingleRfid>
          </Route>
          <Route exact path={`${props.match.path}/insight/co2ReductionList`}>
            <Co2ReductionList></Co2ReductionList>
          </Route>
        </Switch>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.insight.isLoading,
    fetchNotifi: state.insight.notification,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getNotificationAPI: () => dispatch(fetchNotificationDataAPI()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);

