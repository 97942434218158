import React, { useEffect, useMemo, useState } from "react";
import Table from "../../../reacttable/table";
import "../../../reacttable/reactTable.css";
import { FaEye } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Card, Col, Container, Row } from "react-bootstrap";
import PureBreadcrumbs from "../../../breadcrums";
import { fetchAllPartnerTariff, allRoamingPartnerPartnerID, refreshPartnerTariffAction } from "../../../../../store/actions/ocpiAction";


const ViewPartnerTariffList = (props) => {
    const { t } = useTranslation();
    const [partnerName, setPartnerName] = useState("All");

    useEffect(() => {
        props.allPartnerPartyId();
        props.allPartnerTariff(partnerName);
    }, [partnerName])

    const handleChange = (e) => {
        let partnerid = e.target.value
        setPartnerName(partnerid)
    };
    const handleAddClick = () => {
        props.partnerRefreshTariff(partnerName)
    };


    const data = props.list && props.list.data;
    const columns = useMemo(
        () => [
            {
                Header: "Party ID",
                accessor: "party_id",
            },
            {
                Header: "Currency",
                accessor: "currency",
            },
            {
                Header: "Country Code",
                accessor: "country_code",
            },
            {
                Header: "Actions",
                accessor: "id",
                Cell: ({ row }) => {
                    const { original } = row;
                    const { id, party_id } = original;
                    return (
                        <div>
                            <span>
                                <a
                                    href={`/dashboard/view_consumed_tariff/singleocpitariff/${id}/${party_id}`}
                                    rel="tooltip"
                                    title="View"
                                >
                                    <FaEye />
                                </a>
                            </span>
                        </div>
                    );
                },
            },
        ],
        []
    );

    return (
        <Container fluid >
            <PureBreadcrumbs />
            <br />
            <Row>
                <label className="ml-3 d-flex align-items-center" htmlFor="partnerNameDropdown">{t("Partner name")} :</label>
                <Col lg={4}>
                    <select className='form-control form-select' id="partnerNameDropdown" onChange={handleChange}>
                        <option value={"All"}>{t("All")}</option>
                        {props.partyidlist && props.partyidlist.data && props.partyidlist.data.map((item) =>
                            <option key={item.id} value={item.party_id}>{item.partner_name}</option>
                        )}
                    </select>
                </Col>
                {partnerName !== "All" ? <Col className="col-lg-6 col-sm-12 text-right">
                    <a className="refresh" style={{ fontSize: "14px", color: "var(--button-color)", fontWeight: "bold", cursor: "pointer" }} onClick={handleAddClick} >Refresh tariff for : {partnerName} &nbsp;<img style={{ height: "16px" }} src={process.env.PUBLIC_URL + '/images/insight/refresh.svg'} alt="" /></a>
                </Col> : ''}
            </Row>
            <Row >
                <Col className="mt-4">

                    <Card className="customercard">
                        <div className="table-header"> {t("Partner Tariff List")}</div>
                        {props.loading ? <h3 style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "250px",
                        }}>Loading data...</h3> :
                            data && data.length > 0 ? (
                                <div>
                                    <Table data={data} columns={columns}></Table>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                > <h4>{t("No Data Found")}</h4>
                                </div>
                            )}
                    </Card>
                </Col>
            </Row>
        </Container>
    );

}
const mapStateToProps = (state) => {
    return {
        loading: state.ocpi.isLoading,
        partyidlist: state.ocpi.allpartyid,
        list: state.ocpi.fetchAllPartnerTariff,
        partnerTariffRefresh: state.ocpi.tariffRefresh,
        // activateRes: state.ocpi.activateRoamingPartner,
        // deactivateRes: state.ocpi.deactivateRoamingPartner,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        allPartnerPartyId: () => dispatch(allRoamingPartnerPartnerID()),
        allPartnerTariff: (party_id) => dispatch(fetchAllPartnerTariff(party_id)),
        partnerRefreshTariff: (partner_id) => dispatch(refreshPartnerTariffAction(partner_id))

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewPartnerTariffList);

